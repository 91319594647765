<template>
  <div>
    <div class="allTips" v-if="is_owner && detail.market.oid">
      <p>NFT is pending order.</p>
      <a-button class="cancel" :loading="cancelLoading" @click="clickCancel(detail.market.oid)">Cancel</a-button>
    </div>
    <div class="detail-box">
      <div class="detail-left">
        <div class="nft_name">
          {{ detail.token.name }}
          <div class="more" v-if="isMobile" @click="changeDomainVisible"></div>
          <div class="domain_box" v-if="domainVisible">
            <a @click="refresh"></a>
            <a v-for="(item, index) in detail.collect.social" :key="index" :href="item.url" target="_blank">
              <img :src="item.icon" />
            </a>
            <a href="javascript:;" v-if="detail.token.is_owner && !detail.market.oid" @click="showTransferDialog = true">
              <img src="~@/assets/images/market-detail/transfer.svg" />
            </a>
            <a @click="doCopy"></a>
          </div>
        </div>
        <div class="series_box">
          <p class="cursor" @click="goCollection">{{ detail.collect.name }}</p>
          <span v-if="detail.collect.type" @click="goSearchTrade">{{ detail.collect.type }}</span>
          <span v-if="detail.collect.category" @click="goSearchcategory">{{ detail.collect.category }}</span>
          <span v-if="detail.collect.support_eip" @click="goSearchstandard">{{ detail.collect.support_eip }}</span>
        </div>
        <div class="address_box">
          <p>Owned by</p>
          <span v-if="Array.isArray(detail.token.owner)" @click="ownerVisible = true">{{ detail.token.owner.length }} Owners</span>
          <span v-else @click="linkAccount(detail.token.owner)">{{ detail.token.owner | showAddress }}</span>
          <p>{{ detail.token.view }}</p>
        </div>
        <div class="commodity_box">
          <img :src="ipfs(detail.token.icon)" alt="" @click="showImage = true" />
        </div>
        <div class="buy_box">
          <a-tabs type="card" v-model="tabActiveKey" @change="nftTabChange">
            <!-- Trade  has order-->
            <a-tab-pane key="1" tab="Trade" class="buy_main">
              <template v-if="detail.market && detail.market.oid && ![1, 4].includes(detail.market.type)">
                <!-- Trade  has order is_owner-->
                <template v-if="is_owner">
                  <div class="user_trade">
                    <ul class="flex-box align-start">
                      <li>
                        <h5>Current Price</h5>
                        <div class="price_box">
                          <img :src="detail.market.pay_token_icon" alt="" />
                          <h4>{{ detail.market.price | decimals }}</h4>
                          <span> (${{ detail.market.value_usd | decimals(2) }}) </span>
                        </div>
                      </li>
                      <li class="mg-l20" v-if="detail.collect.support_eip == 'ERC-1155'">
                        <h5>Trade Share</h5>
                        <div class="price_box">
                          <h4>{{ detail.market.transfer_amount }}</h4>
                        </div>
                      </li>
                    </ul>
                    <div class="user_time">
                      <p>
                        <a-popover>
                          <template slot="content">
                            {{ timesTime(detail.market.end_time) }}
                          </template>
                          Time Left
                        </a-popover>
                        <span>{{ leftTime(detail.market.end_time) }}</span>
                      </p>
                      <div class="time_container time_containerNo">
                        <span>{{ endTime(detail.market.end_time) }}</span>
                      </div>
                    </div>
                    <div class="lssue">
                      <a-button type="primary" :disabled="true" block> Listing </a-button>
                    </div>
                  </div>
                </template>
                <!-- Trade  has order not_owner-->
                <template v-else>
                  <ul class="flex-box align-start">
                    <li>
                      <p>Current Price</p>
                      <div class="price_box">
                        <img :src="detail.market.pay_token_icon" alt="" />
                        <h4>{{ detail.market.price | decimals }}</h4>
                        <span> (${{ detail.market.value_usd | decimals(2) }}) </span>
                      </div>
                    </li>
                    <li class="mg-l20" v-if="detail.collect.support_eip == 'ERC-1155'">
                      <p>Trade Share</p>
                      <div class="price_box">
                        <h4>{{ detail.market.transfer_amount }}</h4>
                      </div>
                    </li>
                  </ul>
                  <div class="time_box">
                    <a-popover>
                      <template slot="content">
                        {{ timesTime(detail.market.end_time) }}
                      </template>
                      <h4>Time Left</h4>
                    </a-popover>
                    <p>{{ leftTime(detail.market.end_time) }}</p>
                  </div>
                  <div class="button_box">
                    <!-- <div v-if="hasBalance" class="buy_button buy_button_disabled">Insufficient</div> -->
                    <div class="buy_button" @click="() => (showBuyDialog = true)">Buy Now</div>
                    <div class="make_button" @click="openOfferModal('2')">Make Offer</div>
                  </div>
                </template>
              </template>
              <!-- Trade  no order-->
              <template v-else>
                <template v-if="is_offer">
                  <div class="user_trade">
                    <ul class="flex-box align-start">
                      <li>
                        <h5>Current Price</h5>
                        <div class="price_box">
                          <img :src="offerData.pay_token_icon" alt="" />
                          <h4>{{ offerData.price | decimals }}</h4>
                          <span> (${{ offerData.value_usd | decimals(2) }}) </span>
                        </div>
                      </li>
                      <li class="mg-l20" v-if="detail.collect.support_eip == 'ERC-1155'">
                        <h5>Trade Share</h5>
                        <div class="price_box">
                          <h4>{{ offerData.transfer_amount }}</h4>
                        </div>
                      </li>
                    </ul>
                    <div class="user_time">
                      <p>
                        <a-popover>
                          <template slot="content">
                            {{ timesTime(offerData.end_time) }}
                          </template>
                          Time Left
                        </a-popover>
                        <span>{{ leftTime(offerData.end_time) }}</span>
                      </p>
                      <div class="time_container time_containerNo">
                        <span>{{ endTime(offerData.end_time) }}</span>
                      </div>
                    </div>
                    <div class="cancel_offer_btn">
                      <a-button type="primary" :loading="cancelLoading" @click="clickCancel(offerData.oid)" block> Cancel Offer </a-button>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="user_trade">
                    <ul class="user_trade_ul">
                      <li>
                        <h5>Current Price</h5>
                        <div class="user_price">
                          <div class="price_ipt">
                            <a-input-number v-model="issueTrade.price" placeholder="0.0000" :precision="4" :min="0.0001"> </a-input-number>
                          </div>
                          <div class="price_type">
                            <a-select dropdownClassName="price_type" v-model="issueTrade.pay_token">
                              <template slot="suffixIcon">
                                <img src="@/assets/images/market-detail/down_mo.png" alt="" />
                              </template>
                              <a-select-option v-for="(item, index) in detail.pay_token" :key="index" :value="item.address">{{ item.name }}</a-select-option>
                            </a-select>
                          </div>
                          <span v-if="false">($0.43)</span>
                        </div>
                      </li>
                      <li v-if="detail.collect.support_eip == 'ERC-1155'">
                        <h5>Trade Share</h5>
                        <div class="user_price">
                          <div class="price_type mg-l0">
                            <a-select dropdownClassName="price_type" v-model="issueTrade.times">
                              <template slot="suffixIcon">
                                <img src="@/assets/images/market-detail/down_mo.png" alt="" />
                              </template>
                              <a-select-option v-for="i in detail.token.balance" :key="i" :value="i">{{ i }}</a-select-option>
                            </a-select>
                          </div>
                        </div>
                      </li>
                    </ul>
                    <div class="user_time">
                      <p>
                        <a-popover>
                          <template slot="content">
                            {{ timesTime(issueTrade.end_time) }}
                          </template>
                          Time Left
                        </a-popover>
                        <span>{{ leftTime(issueTrade.end_time) }}</span>
                      </p>
                      <div class="time_container">
                        <a-date-picker v-model="issueTrade.end_time" :disabled-date="disabledDate" :disabled-time="disabledDateTime" :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }" />
                      </div>
                    </div>
                    <div class="lssue">
                      <template v-if="is_owner">
                        <a-button type="primary" :loading="connectedLoading" @click="clickIssue('sale')" block> Sell </a-button>
                      </template>
                      <template v-else>
                        <a-button type="primary" :loading="connectedLoading" @click="clickIssue('sale')" block> Make Offer </a-button>
                      </template>
                    </div>
                  </div>
                </template>
              </template>
            </a-tab-pane>
            <!-- rental -->
            <a-tab-pane key="2" tab="Rental" class="Rental_main">
              <!-- have order -->
              <template v-if="detail.market && detail.market.oid && [1, 4].includes(detail.market.type)">
                <!-- owner =>rental  -->
                <template v-if="is_owner">
                  <div class="user_rental">
                    <ul>
                      <li>
                        <p>Rental Price</p>
                        <div class="flex">
                          <img :src="detail.market.pay_token_icon" alt="" />
                          <span>{{ detail.market.price | decimals }}</span>
                          <i>(${{ detail.market.value_usd | decimals(2) }})</i>
                        </div>
                      </li>
                      <li v-if="detail.collect.support_eip == 'ERC-5187'">
                        <p>Rental Share</p>
                        <span>{{ detail.market.rental_amount }}</span>
                      </li>
                      <li>
                        <p>Rental Period</p>
                        <span>{{ timeToString(detail.market.rental_period) }}</span>
                      </li>
                    </ul>
                    <div class="user_time">
                      <p>
                        <a-popover>
                          <template slot="content">
                            {{ timesTime(detail.market.end_time) }}
                          </template>
                          Time Left </a-popover
                        ><span>{{ leftTime(detail.market.end_time) }}</span>
                      </p>
                      <div class="time_container time_containerNo">
                        <span>{{ endTime(detail.market.end_time) }}</span>
                      </div>
                    </div>
                    <div class="lssue">
                      <a-button type="primary" :disabled="true" block> Listing </a-button>
                    </div>
                  </div>
                </template>
                <!--other user rental -->
                <template v-else>
                  <!--other user rental mobile -->
                  <template v-if="isMobile">
                    <ul>
                      <li>
                        <p>Rental Price</p>
                        <div class="flex">
                          <img :src="detail.market.pay_token_icon" alt="" />
                          <span>{{ detail.market.price | decimals }}</span>
                          <i>(${{ detail.market.value_usd | decimals(2) }})</i>
                        </div>
                      </li>
                    </ul>
                    <div class="time_box">
                      <ul>
                        <li v-if="detail.collect.support_eip == 'ERC-5187'">
                          <p>Rental Share</p>
                          <span>{{ detail.market.rental_amount }}</span>
                        </li>
                        <li>
                          <p>Rental Period</p>
                          <span>{{ timeToString(detail.market.rental_period) }}</span>
                        </li>
                        <li>
                          <a-popover>
                            <template slot="content">
                              {{ timesTime(detail.market.end_time) }}
                            </template>
                            <p>Time Left</p>
                          </a-popover>
                          <span>{{ leftTime(detail.market.end_time) }}</span>
                        </li>
                      </ul>
                    </div>
                    <div class="button_box">
                      <div
                        class="buy_button"
                        @click="
                          () => {
                            showRentDialog = true;
                          }
                        "
                      >
                        Rental
                      </div>
                      <div class="make_button" @click="openOfferModal('4')">Make Offer</div>
                    </div>
                  </template>
                  <!--other user rental pc -->
                  <template v-else>
                    <ul>
                      <li>
                        <p>Rental Price</p>
                        <div class="flex">
                          <img :src="detail.market.pay_token_icon" alt="" />
                          <span>{{ detail.market.price | decimals }}</span>
                          <i>(${{ detail.market.value_usd | decimals(2) }})</i>
                        </div>
                      </li>
                      <li v-if="detail.collect.support_eip == 'ERC-5187'">
                        <p>Rental Share</p>
                        <span>{{ detail.market.rental_amount }}</span>
                      </li>
                      <li>
                        <p>Rental Period</p>
                        <span>{{ timeToString(detail.market.rental_period) }}</span>
                      </li>
                    </ul>
                    <div class="time_box">
                      <a-popover>
                        <template slot="content">
                          {{ timesTime(detail.market.end_time) }}
                        </template>
                        <h4>Time Left</h4>
                      </a-popover>
                      <p>{{ leftTime(detail.market.end_time) }}</p>
                    </div>
                    <div class="button_box">
                      <div v-if="hasBalance" class="buy_button buy_button_disabled">Rental</div>
                      <div v-else class="buy_button" @click="() => (showRentDialog = true)">Rental</div>
                      <div class="make_button" @click="openOfferModal('4')">Make Offer</div>
                    </div>
                  </template>
                </template>
              </template>
              <!-- rental no order -->
              <template v-else>
                <template v-if="is_offer">
                  <div class="user_rental">
                    <ul>
                      <li>
                        <p>Rental Price</p>
                        <div class="flex">
                          <img :src="offerData.pay_token_icon" alt="" />
                          <span>{{ offerData.price | decimals }}</span>
                          <i>(${{ offerData.value_usd | decimals(2) }})</i>
                        </div>
                      </li>
                      <li v-if="detail.collect.support_eip == 'ERC-5187'">
                        <p>Rental Share</p>
                        <span>{{ offerData.rental_amount }}</span>
                      </li>
                      <li>
                        <p>Rental Period</p>
                        <span>{{ timeToString(offerData.rental_period) }}</span>
                      </li>
                    </ul>
                    <div class="user_time">
                      <p>
                        <a-popover>
                          <template slot="content">
                            {{ timesTime(offerData.end_time) }}
                          </template>
                          Time Left </a-popover
                        ><span>{{ leftTime(offerData.end_time) }}</span>
                      </p>
                      <div class="time_container time_containerNo">
                        <span>{{ endTime(offerData.end_time) }}</span>
                      </div>
                    </div>
                    <div class="cancel_offer_btn">
                      <a-button type="primary" :loading="cancelLoading" @click="clickCancel(offerData.oid)" block> Cancel Offer </a-button>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="user_rental">
                    <template v-if="is_rent">
                      <ul>
                        <li v-if="detail.collect.support_eip == 'ERC-5187'">
                          <p>Rental Share</p>
                          <span>{{ detail.token.balance }}</span>
                        </li>
                      </ul>
                      <div class="user_time">
                        <p>
                          <a-popover>
                            <template slot="content">
                              {{ timesTime(detail.market.end_time) }}
                            </template>
                            Time Left </a-popover
                          ><span>{{ leftTime(detail.token.expiration) }}</span>
                        </p>
                        <div class="time_container time_containerNo">
                          <span>{{ endTime(detail.token.expiration) }}</span>
                        </div>
                      </div>
                    </template>
                    <template v-else>
                      <div class="user_price">
                        <div class="price_ipt">
                          <h5>Rental Price</h5>
                          <a-input-number v-model="issueTrade.price" placeholder="0.0000" :precision="4" :min="0.0001"> </a-input-number>
                        </div>
                        <div class="price_type">
                          <a-select dropdownClassName="price_type" v-model="issueTrade.pay_token">
                            <template slot="suffixIcon">
                              <img src="@/assets/images/market-detail/down_mo.png" alt="" />
                            </template>
                            <a-select-option v-for="(item, index) in detail.pay_token" :key="index" :value="item.address">{{ item.name }}</a-select-option>
                          </a-select>
                        </div>
                        <span v-if="false">($0.43)</span>
                        <div class="rental_time" v-if="detail.collect.support_eip == 'ERC-5187'">
                          <h5>Rental Share</h5>
                          <div class="rentalTime_box">
                            <a-select dropdownClassName="price_type" v-model="issueTrade.times">
                              <template slot="suffixIcon">
                                <img src="@/assets/images/market-detail/down_mo.png" alt="" />
                              </template>

                              <a-select-option v-for="i in detail.token.balance" :key="i" :value="i">{{ i }}</a-select-option>
                            </a-select>
                          </div>
                        </div>
                        <div class="period_box">
                          <h5>Rental Period</h5>
                          <input type="text" v-model="issueTrade.period" placeholder="1-999 Days" oninput="value=value.replace(/[^\d]/g,'')" />
                        </div>
                      </div>
                      <div class="user_time">
                        <p>
                          <a-popover>
                            <template slot="content">
                              {{ timesTime(issueTrade.end_time) }}
                            </template>
                            Time Left
                          </a-popover>
                          <span>{{ leftTime(issueTrade.end_time) }}</span>
                        </p>
                        <div class="time_container">
                          <a-date-picker v-model="issueTrade.end_time" :disabled-date="disabledDate" :disabled-time="disabledDateTime" :show-time="{ defaultValue: moment('00:00:00', 'HH:mm:ss') }" />
                        </div>
                      </div>
                      <div class="lssue">
                        <template v-if="is_owner">
                          <a-button type="primary" :loading="connectedLoading" @click="clickIssue('rent')" block> Rent </a-button>
                        </template>
                        <a-button v-else type="primary" :loading="connectedLoading" @click="clickIssue('rent')" block> Make Offer </a-button>
                      </div>
                    </template>
                  </div>
                </template>
              </template>
              <template v-if="rental_expire > 0">
                <div>Rent expire:{{ rental_expire }}</div>
                <div v-if="rental_balance > 0">Rent share:{{ rental_balance }}</div>
              </template>
            </a-tab-pane>
            <a-tab-pane key="3" tab="Financial"> </a-tab-pane>
            <a-tab-pane key="4" tab="Lease"> </a-tab-pane>
            <a-tab-pane key="5" tab="Fractionalization"> </a-tab-pane>
            <a-tab-pane key="6" tab="Lending"> </a-tab-pane>
            <a-tab-pane key="7" tab="Barter"> </a-tab-pane>
          </a-tabs>
        </div>
        <div class="office_box" :class="{ officeHeight: officeHeight }">
          <a-collapse v-model="activeKey" @change="changeActivekey" expand-icon-position="right">
            <template #expandIcon="props">
              <img src="@/assets/images/market-detail/down.png" alt="" :style="{ transform: `rotate(${props.isActive ? 0 : 180}deg)` }" v-if="!isMobile" />
              <img src="@/assets/images/market-detail/down_mo.png" alt="" :style="{ transform: `rotate(${props.isActive ? 0 : 180}deg)` }" v-else />
            </template>
            <a-collapse-panel header="Offers" key="1">
              <table>
                <tr class="title">
                  <td>Price</td>
                  <td>USD Price</td>
                  <td>Expiration</td>
                  <td>From</td>
                </tr>
                <div class="table_main" v-if="offer.list.length > 0">
                  <div class="offer-table-scroll" v-infinite-scroll="offerInfiniteOnLoad" :infinite-scroll-disabled="offer.busy" :infinite-scroll-distance="10">
                    <a-list :data-source="offer.list">
                      <a-list-item slot="renderItem" slot-scope="item">
                        <tr>
                          <td>
                            <img :src="item.pay_token_icon" alt="" />
                            <p>{{ item.price | decimals }}</p>
                            <span>{{ item.pay_token_name }}</span>
                          </td>
                          <td>
                            <p>${{ item.value_usd | decimals }}</p>
                          </td>
                          <td>
                            <p>
                              <template v-if="item.expiration > now"> {{ leftTime(item.expiration) }} </template>
                              <template v-else> Expired </template>
                            </p>
                          </td>
                          <td>
                            <p class="cursor" @click="linkAccount(item.offeror)">
                              {{ item.offeror | showAddress }}
                            </p>
                          </td>
                        </tr>
                      </a-list-item>
                    </a-list>
                  </div>
                </div>
                <div class="no_offers" v-else>
                  <p>No Offers Yet</p>
                </div>
              </table>
            </a-collapse-panel>
          </a-collapse>
        </div>
      </div>
      <div class="detail-right">
        <div class="right_top" v-if="!isMobile">
          <a @click="refresh"></a>
          <a v-for="(item, index) in detail.collect.social" :key="index" :href="item.url" target="_blank">
            <img :src="item.icon" />
          </a>
          <a href="javascript:;" v-if="detail.token.is_owner && !detail.market.oid" @click="showTransferDialog = true">
            <img src="~@/assets/images/market-detail/transfer.svg" />
          </a>
          <a @click="doCopy"></a>
        </div>
        <div class="commodity_box" v-if="!isMobile">
          <img :src="ipfs(detail.token.icon)" alt="" @click="showImage = true" />
        </div>
        <div class="information_box" :class="{ informationHeight: informationHeight }">
          <a-collapse v-model="activeKey2" @change="changeActivekey2" expand-icon-position="right">
            <template #expandIcon="props">
              <img src="@/assets/images/market-detail/down.png" alt="" :style="{ transform: `rotate(${props.isActive ? 0 : 180}deg)` }" v-if="!isMobile" />
              <img src="@/assets/images/market-detail/down_mo.png" alt="" :style="{ transform: `rotate(${props.isActive ? 0 : 180}deg)` }" v-else />
            </template>
            <a-collapse-panel header="Details" key="1">
              <ul>
                <li>
                  <p>Contract Address</p>
                  <a :href="detail.collect.contract_link" target="_blank">{{ detail.collect.address | showAddress }}</a>
                </li>
                <li>
                  <p>Token ID</p>
                  <a :href="detail.token.link" target="_blank">{{ detail.token.token_id }}</a>
                </li>
                <li>
                  <p>Token Standard</p>
                  <span>{{ detail.collect.support_eip }}</span>
                </li>
                <li>
                  <p>Blockchain</p>
                  <span>{{ detail.collect.chain_name }}</span>
                </li>
                <li>
                  <p>Owner</p>
                  <span class="cursor" v-if="Array.isArray(detail.token.owner)" @click="ownerVisible = true">{{ detail.token.owner.length }} Owners</span>
                  <span v-else @click="linkAccount(detail.token.owner)">{{ detail.token.owner | showAddress }}</span>
                </li>
                <li class="fee">
                  <a-popover>
                    <template slot="content">
                      <p>This NFT project charges {{ detail.collect.fee_rate }}% per<br />transaction for platform fees</p>
                    </template>
                    <p>
                      Fee
                      <i></i>
                    </p>
                  </a-popover>
                  <span>{{ detail.collect.fee_rate }}%</span>
                </li>
                <li>
                  <p>Creator Earnings</p>
                  <span>{{ detail.collect.creator_earning }}%</span>
                </li>
              </ul>
            </a-collapse-panel>
          </a-collapse>
        </div>
      </div>
    </div>
    <div class="activity_box" :class="{ activityHeight: activityHeight }">
      <a-collapse v-model="activeKey3" @change="changeActivekey3" expand-icon-position="right">
        <template #expandIcon="props">
          <img src="@/assets/images/market-detail/down.png" alt="" :style="{ transform: `rotate(${props.isActive ? 0 : 180}deg)` }" v-if="!isMobile" />
          <img src="@/assets/images/market-detail/down_mo.png" alt="" :style="{ transform: `rotate(${props.isActive ? 0 : 180}deg)` }" v-else />
        </template>
        <a-collapse-panel header="Activity" key="1">
          <div class="lable_box">
            <a-radio-group button-style="solid" v-model="activity.type" @change="onActivityChange">
              <a-radio-button v-for="(val, key, index) in activity.typeDict" :key="index" :value="key">
                {{ val }}
              </a-radio-button>
            </a-radio-group>
          </div>
          <div class="activity_main">
            <div class="activity-table-scroll" v-infinite-scroll="activityInfiniteOnLoad" :infinite-scroll-disabled="activity.busy" :infinite-scroll-distance="10">
              <a-list :data-source="activity.list">
                <a-list-item slot="renderItem" slot-scope="item">
                  <div class="activity-info">
                    <div class="user_img">
                      <img :src="ipfs(item.icon)" alt="" />
                    </div>
                    <div class="user_information">
                      <div>
                        <div class="user_name">
                          <p>{{ item.name }}</p>
                          <span>{{ activity.typeDict[item.type] }}</span>
                        </div>
                        <div class="right_box">
                          <div class="user_from">
                            From
                            <p>{{ item.from | showAddress }}</p>
                            <template v-if="[1, 2, 3, 8].includes(item.type)">
                              to
                              <p>{{ item.to | showAddress }}</p>
                            </template>
                            <span>
                              <a-popover>
                                <template slot="content">
                                  <p>{{ timesTime(item.datetime) }}</p>
                                </template>
                                <a-button type="primary"> {{ transformTime(item.datetime) }} ago </a-button>
                              </a-popover>
                            </span>
                            <a :href="item.link" target="_blank"></a>
                            <template v-if="item.end_time">
                              <p v-if="item.end_time > now">Expiry {{ leftTime(item.end_time) }}</p>
                              <p v-else>Canceled</p>
                            </template>
                          </div>
                        </div>
                      </div>
                      <div>
                        <div class="price_box" v-if="item.type != 2">
                          <img :src="ipfs(item.pay_token_icon)" alt="" />
                          <p>{{ item.price | decimals }}</p>
                          <span>(${{ item.value | decimals(2) }})</span>
                        </div>
                        <div class="rent-box" v-if="[1, 4].includes(item.order_type)">
                          <p v-if="item.support_eip == 'ERC-5187'">Rental Share {{ item.rental_amount }}</p>
                          <p>Rental Period {{ timeToString(item.rental_period) }}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </a-list-item>
              </a-list>
            </div>
          </div>
        </a-collapse-panel>
      </a-collapse>
    </div>
    <a-modal
      v-model="showBuyDialog"
      centered
      @ok="() => (showBuyDialog = false)"
      :footer="null"
      :maskClosable="false"
      class="buyNow"
      :class="{ 'buyNow-collapse': activeKey4.length > 0 }"
      :width="610"
    >
      <div class="title">
        <h4>Buy Now</h4>
        <img src="@/assets/images/market-detail/close_ico.svg" alt="" @click="showBuyDialog = false" />
      </div>
      <div class="information">
        <div class="commodity">
          <img :src="ipfs(detail.token.icon)" alt="" @click="showImage = true" />
        </div>
        <div class="information_main">
          <div class="main_left">
            <p>{{ detail.collect.name }}</p>
            <span>{{ detail.token.name }}</span>
            <a-popover>
              <template slot="content">
                <p>This NFT project charges {{ detail.collect.fee_rate }}% per<br />transaction for platform fees</p>
              </template>
              <p class="fee">
                Fee
                <i></i>
              </p>
            </a-popover>
          </div>
          <div class="main_right">
            <div class="price">
              <img :src="detail.market.pay_token_icon" alt="" />
              <p>{{ detail.market.price | decimals }}</p>
              <i>(${{ detail.market.value_usd | decimals(2) }})</i>
            </div>
            <span>{{ detail.collect.fee_rate }}</span>
          </div>
        </div>
      </div>
      <a-collapse v-model="activeKey4" expand-icon-position="right">
        <template #expandIcon="props">
          <img src="@/assets/images/market-detail/down.png" alt="" :style="{ transform: `rotate(${props.isActive ? 0 : 180}deg)` }" />
        </template>
        <a-collapse-panel header="Send to a different wallet" key="1">
          <input type="text" v-model="buyAddress" placeholder="e.g. 0x3943… or meadowfair.eth" class="wallet_input" />
          <span class="tips">Make sure you're sending to the correct address as lost items can't be recovered.</span>
        </a-collapse-panel>
      </a-collapse>
      <a-button class="btn_buy" type="primary" :loading="TakeLoading" @click="clickTake" block> Buy </a-button>
    </a-modal>
    <a-modal v-model="modal2Visible" centered @ok="() => (modal2Visible = false)" :footer="null" :maskClosable="false" class="make_box" :width="610">
      <div class="title">
        <h4>Make Offer</h4>
        <img src="@/assets/images/market-detail/close_ico.svg" alt="" @click="setModal2Visible(false)" />
      </div>
      <div class="information">
        <div class="commodity">
          <img :src="ipfs(detail.token.icon)" alt="" />
        </div>
        <div class="information_main">
          <div class="main_left">
            <p>{{ detail.collect.name }}</p>
            <span>{{ detail.token.name }}</span>
            <a-popover>
              <template slot="content">
                <p>This NFT project charges {{ detail.collect.fee_rate }}% per<br />transaction for platform fees</p>
              </template>
              <p class="fee">
                Fee
                <i></i>
              </p>
            </a-popover>
          </div>
          <div class="main_right">
            <div class="price">
              <img :src="detail.market.pay_token_icon" alt="" />
              <p>{{ detail.market.price | decimals }}</p>
              <i>(${{ detail.market.value_usd | decimals(2) }})</i>
            </div>
            <span>{{ detail.collect.fee_rate }}</span>
          </div>
        </div>
      </div>
      <div class="balance_box">
        <p>Your Offer</p>
        <span>Balance: {{ OfferBalance | decimals }} {{ detail.market.pay_token_name }}</span>
      </div>
      <template v-if="is_offer">
        <div class="price_box">
          <img :src="offerData.pay_token_icon" alt="" />
          <h4>{{ offerData.price | decimals }}</h4>
          <span> (${{ offerData.value_usd | decimals(2) }}) </span>
        </div>
        <div class="user_time" v-if="detail.collect.support_eip == 'ERC-1155'">
          <span class="title_day">Trade Share</span>
          <div class="time_container">
            <span>{{ offerData.transfer_amount }}</span>
          </div>
        </div>
        <div class="user_time">
          <a-popover>
            <template slot="content">
              {{ timesTime(offerData.end_time) }}
            </template>
            <span class="title_day"
              >Validity of Offer<span class="mg-l8">{{ leftTime(offerData.end_time) }}</span></span
            >
          </a-popover>
          <div class="time_container">
            <span>{{ endTime(offerData.end_time) }}</span>
          </div>
        </div>
        <div class="offer_btn">
          <a-button type="primary" :loading="cancelLoading" @click="clickCancel(offerData.oid)" block> Cancel Offer </a-button>
        </div>
      </template>
      <template v-else>
        <div>
          <a-input-number class="balance_ipt" v-model="issueTrade.price" placeholder="0.0000" :precision="4" :min="0.0001"> </a-input-number>
          <p class="eth">{{ detail.market.pay_token_name }}</p>
        </div>
        <template v-if="detail.collect.support_eip == 'ERC-1155'">
          <span class="title_day">Trade Share</span>
          <div class="day_main">
            <a-select v-model="issueTrade.times" dropdownClassName="day_select">
              <template slot="suffixIcon">
                <img src="@/assets/images/market-detail/down.png" alt="" />
              </template>
              <a-select-option v-for="i in detail.token.balance" :key="i" :value="i">{{ i }}</a-select-option>
            </a-select>
          </div>
        </template>
        <span class="title_day">Validity of Offer</span>
        <div class="day_main">
          <a-select v-model="issueTrade.validity_time" dropdownClassName="day_select">
            <template slot="suffixIcon">
              <img src="@/assets/images/market-detail/down.png" alt="" />
            </template>
            <a-select-option value="1"> 1 Day </a-select-option>
            <a-select-option value="3"> 3 Days </a-select-option>
            <a-select-option value="7"> 7 Days </a-select-option>
            <a-select-option value="14"> 14 Days </a-select-option>
            <a-select-option value="30"> 30 Days </a-select-option>
          </a-select>
        </div>
        <a-button class="make_btn" type="primary" :loading="connectedLoading" @click="clickMakeOffer" block> Make Offer </a-button>
      </template>
    </a-modal>
    <a-modal v-model="showRentDialog" centered @ok="() => (showRentDialog = false)" :footer="null" :maskClosable="false" class="rent" :class="{ 'rent-collapse': activeKey5.length == 0 }" :width="610">
      <div class="title">
        <h4>Rent</h4>
        <img src="@/assets/images/market-detail/close_ico.svg" alt="" @click="() => (showRentDialog = false)" />
      </div>
      <div class="information">
        <div class="commodity">
          <img :src="ipfs(detail.token.icon)" alt="" />
        </div>
        <div class="information_main">
          <div class="main_left">
            <p>{{ detail.collect.name }}</p>
            <span>{{ detail.token.name }}</span>
          </div>
          <div class="main_right">
            <div class="price">
              <div class="flex-box">
                <img :src="detail.market.pay_token_icon" alt="" />
                <p>{{ detail.market.price | decimals }}</p>
              </div>
              <i>(${{ detail.market.value_usd | decimals(2) }})</i>
            </div>
            <div v-if="detail.collect.support_eip == 'ERC-5187'" class="time">{{ detail.market.rental_amount }}&nbsp;times</div>
            <div class="days">{{ timeToString(detail.market.rental_period) }}</div>
          </div>
          <a-popover v-if="!isMobile">
            <template slot="content">
              <p>This NFT project charges {{ detail.collect.fee_rate }}% per<br />transaction for platform fees</p>
            </template>
            <p class="fee">
              Fee
              <i></i>
              <span>{{ detail.collect.fee_rate }}%</span>
            </p>
          </a-popover>
        </div>
        <div class="fee" v-if="isMobile">
          <a-popover>
            <template slot="content">
              <p>This NFT project charges {{ detail.collect.fee_rate }}% per<br />transaction for platform fees</p>
            </template>
            <em class="flex">
              Fee
              <i></i>
            </em>
          </a-popover>
          <span>{{ detail.collect.fee_rate }}%</span>
        </div>
      </div>
      <div class="rent_main">
        <!-- <h5>Rental Period</h5>
                <div class="days">
                    <input type="text" placeholder="Min 1 Day, Max 999 Day" />
                    <p>Day</p>
                </div>
                <h5>Rental Price</h5>
                <div class="price">
                    <span>{{ detail.market.price | decimals }}</span>
                    <p>{{ detail.market.pay_token_name }}</p>
                </div> -->
      </div>
      <a-collapse v-model="activeKey5" expand-icon-position="right">
        <template #expandIcon="props">
          <img src="@/assets/images/market-detail/down.png" alt="" :style="{ transform: `rotate(${props.isActive ? 0 : 180}deg)` }" />
        </template>
        <a-collapse-panel header="Send to a different wallet" key="1">
          <input type="text" placeholder="e.g. 0x3943… or meadowfair.eth" class="wallet_input" />
          <span class="tips">Make sure you're sending to the correct address as lost items can't be recovered.</span>
        </a-collapse-panel>
      </a-collapse>
      <a-button class="rent_btn" :loading="TakeLoading" @click="clickTake">Rent</a-button>
    </a-modal>
    <a-modal
      v-model="modal4Visible"
      centered
      @ok="() => (modal4Visible = false)"
      :footer="null"
      :maskClosable="false"
      class="Rent_make"
      :class="{ 'Rent_make-collapse': activeKey6.length == 0 }"
      :width="610"
    >
      <div class="title">
        <h4>Make Offer</h4>
        <img src="@/assets/images/market-detail/close_ico.svg" alt="" @click="() => (modal4Visible = false)" />
      </div>
      <div class="information">
        <div class="commodity">
          <img :src="ipfs(detail.token.icon)" alt="" />
        </div>
        <div class="information_main">
          <div class="main_left">
            <p>{{ detail.collect.name }}</p>
            <span>{{ detail.token.name }}</span>
          </div>
          <div class="main_right">
            <div class="price">
              <div class="flex-box">
                <img :src="detail.market.pay_token_icon" alt="" />
                <p>{{ detail.market.price | decimals }}</p>
              </div>
              <i>(${{ detail.market.value_usd | decimals(2) }})</i>
            </div>
            <div class="time" v-if="detail.collect.support_eip == 'ERC-5187'">{{ detail.market.rental_amount }}</div>
            <div class="days">{{ timeToString(detail.market.rental_period) }}</div>
          </div>
          <a-popover v-if="!isMobile">
            <template slot="content">
              <p>This NFT project charges {{ detail.collect.fee_rate }}% per<br />transaction for platform fees</p>
            </template>
            <p class="fee">
              Fee
              <i></i>
              <span>{{ detail.collect.fee_rate }}</span>
            </p>
          </a-popover>
        </div>
        <div class="fee" v-if="isMobile">
          <a-popover>
            <template slot="content">
              <p>This NFT project charges {{ detail.collect.fee_rate }}% per<br />transaction for platform fees</p>
            </template>
            <em class="flex">
              Fee
              <i></i>
            </em>
          </a-popover>
          <span>{{ detail.collect.fee_rate }}</span>
        </div>
      </div>
      <template v-if="is_offer">
        <div class="make_main">
          <h5>
            Your Offer
            <p>
              Balance: <span>{{ OfferBalance | decimals }}</span> {{ detail.market.pay_token_name }}
            </p>
          </h5>
          <div class="price_box">
            <img :src="offerData.pay_token_icon" alt="" />
            <h4>{{ offerData.price | decimals }}</h4>
            <span> (${{ offerData.value_usd | decimals(2) }}) </span>
          </div>
          <template v-if="detail.collect.support_eip == 'ERC-5187'">
            <h5>Rental Share</h5>
            <div class="day_main">
              <p>{{ offerData.rental_amount }}</p>
            </div>
          </template>
        </div>
        <div class="offer_day user_time">
          <a-popover>
            <template slot="content">
              {{ timesTime(offerData.end_time) }}
            </template>
            <h5>
              Validity of Offer<span class="mg-l8">{{ leftTime(offerData.end_time) }}</span>
            </h5>
          </a-popover>
          <div class="time_container">
            <span>{{ endTime(offerData.end_time) }}</span>
          </div>
        </div>
        <div class="offer_btn">
          <a-button type="primary" :loading="cancelLoading" @click="clickCancel(offerData.oid)" block> Cancel Offer </a-button>
        </div>
      </template>
      <template v-else>
        <div class="make_main">
          <h5>
            Your Offer
            <p>
              Balance: <span>{{ OfferBalance | decimals }}</span> {{ detail.market.pay_token_name }}
            </p>
          </h5>
          <div class="rental_price">
            <a-input-number v-model="issueTrade.price" placeholder="0.0000" :precision="4" :min="0.0001"> </a-input-number>
            <p>{{ detail.market.pay_token_name }}</p>
          </div>
          <template v-if="detail.collect.support_eip == 'ERC-5187'">
            <h5>Rental Share</h5>
            <div class="day_main">
              <a-select dropdownClassName="day_select" v-model="issueTrade.times">
                <template slot="suffixIcon">
                  <img src="@/assets/images/market-detail/down.png" alt="" />
                </template>

                <a-select-option v-for="i in detail.token.balance" :key="i" :value="i">{{ i }}</a-select-option>
              </a-select>
            </div>
          </template>
        </div>
        <a-collapse v-model="activeKey6" expand-icon-position="right" v-if="false">
          <template #expandIcon="props">
            <img src="@/assets/images/market-detail/down.png" alt="" :style="{ transform: `rotate(${props.isActive ? 0 : 180}deg)` }" />
          </template>
          <a-collapse-panel header="Send to a different wallet" key="1">
            <input type="text" v-model="buyAddress" placeholder="e.g. 0x3943… or meadowfair.eth" class="wallet_input" />
            <span class="tips">Make sure you're sending to the correct address as lost items can't be recovered.</span>
          </a-collapse-panel>
        </a-collapse>
        <div class="offer_day">
          <h5>Validity of Offer</h5>
          <div class="day_main">
            <a-select v-model="issueTrade.validity_time" dropdownClassName="day_select">
              <template slot="suffixIcon">
                <img src="@/assets/images/market-detail/down.png" alt="" />
              </template>
              <a-select-option value="1"> 1 Day </a-select-option>
              <a-select-option value="3"> 3 Days </a-select-option>
              <a-select-option value="7"> 7 Days </a-select-option>
              <a-select-option value="14"> 14 Days </a-select-option>
              <a-select-option value="30"> 30 Days </a-select-option>
            </a-select>
          </div>
        </div>
        <a-button class="rent_btn" type="primary" :loading="connectedLoading" @click="clickMakeOffer('rent')" block> Make Offer </a-button>
      </template>
    </a-modal>
    <a-modal v-model="showImage" :centered="true" :footer="null" width="100%" class="cyrox-modal" :class="{ 'cyrox-modal-mobile': isMobile }">
      <div class="flex-box space-center cyrox-modal-image">
        <img :src="ipfs(detail.token.icon)" @click="showImage = false" />
      </div>
    </a-modal>
    <a-modal v-model="ownerVisible" title="Owned by" @ok="() => (ownerVisible = false)" :centered="true" :footer="null" class="owner-modal">
      <p v-for="address in detail.token.owner" :key="address" @click="linkDetail(address)">{{ address }}</p>
    </a-modal>

    <a-modal v-model="showTransferDialog" centered @ok="() => (showTransferDialog = false)" :footer="null" :maskClosable="false" class="buyNow buyNow-collapse" :width="610">
      <div class="title">
        <h4>Transfer NFT</h4>
        <img src="@/assets/images/market-detail/close_ico.svg" alt="" @click="showTransferDialog = false" />
      </div>
      <div class="information">
        <div class="commodity">
          <img :src="ipfs(detail.token.icon)" alt="" @click="showImage = true" />
        </div>
        <div class="information_main">
          <div class="main_left">
            <p>{{ detail.collect.name }}</p>
            <span>{{ detail.token.name }}</span>
          </div>
        </div>
      </div>
      <div data-v-1e0602ca="" class="ant-collapse ant-collapse-icon-position-left">
        <div data-v-1e0602ca="" role="tablist" class="ant-collapse-item ant-collapse-item-active">
          <div role="button" tabindex="0" class="ant-collapse-header">Receiving Address</div>
          <div class="ant-collapse-content ant-collapse-content-active" style="">
            <div class="ant-collapse-content-box">
              <input data-v-1e0602ca="" type="text" v-model="receiveAddress" placeholder="e.g. 0x3943… or meadowfair.eth" class="wallet_input" /><span data-v-1e0602ca="" class="tips"
                >Make sure you're sending to the correct address as lost items can't be recovered.</span
              >
            </div>
          </div>
        </div>
      </div>
      <a-button class="btn_buy" type="primary" :loading="transferLoading" @click="clickTransfer" block> Transfer </a-button>
    </a-modal>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { nftDetail, activity, collectionOffer, updateMeta, userOffer } from '@/api/index';
import { constants, ethers } from 'ethers';
import moment from 'moment';
import BigNumber from 'bignumber.js';
import infiniteScroll from 'vue-infinite-scroll';
import nftSdk from '@/sdk';
import { confirmTransaction } from '@/utils/trans';
import { MARKET_CONTRACT } from '@/config/constants';
const CID = require('cids');
export default {
  name: 'market-detail',
  directives: { infiniteScroll },
  data() {
    return {
      moment,
      tabActiveKey: '',
      defaultActiveKey: '1',
      activeKey: ['1'], //offers
      activeKey2: ['1'], //details
      activeKey3: ['1'], //activity
      activeKey4: [],
      activeKey5: [],
      activeKey6: [],
      connectedLoading: false,
      officeHeight: false,
      informationHeight: false,
      activityHeight: false,
      showBuyDialog: false,
      modal2Visible: false,
      showRentDialog: false,
      modal4Visible: false,
      domainVisible: false,
      showTransferDialog: false,
      valuePriceTime: 'Eth',
      valuePriceTime2: '',
      now: parseInt(new Date().getTime() / 1000),
      id: this.$route.params.id, // router id
      detail: {
        token: {},
        market: {},
        collect: {},
        activity: [],
        offers: [],
        pay_token: [],
      },
      activity: {
        list: [],
        typeDict: {},
        type: '',
        pagination: {
          current: 1,
          total: 0,
          pageSize: 20,
          size: 'small',
        },
        loading: false,
        busy: false,
      },
      offer: {
        list: [],
        pagination: {
          current: 1,
          total: 0,
          pageSize: 20,
          size: 'small',
        },
        loading: false,
        busy: false,
      },
      issueTrade: {
        price: '',
        pay_token: '',
        end_time: '',
        times: '1',
        period: '',
        validity_time: '',
      },
      buyAddress: '',
      cancelLoading: false,
      TakeLoading: false,
      showImage: false,
      //EIP-5187 hash balance can not rent
      hasBalance: false,
      rental_balance: 0,
      rental_expire: 0,
      OfferBalance: 0,
      offerData: null,
      ownerVisible: false,
      transferLoading: false,
      receiveAddress: '',
    };
  },
  computed: {
    ...mapState({
      account: (state) => state.account,
      isMobile: (state) => state.isMobile,
      userBalance: (state) => state.userBalance,
    }),
    is_owner() {
      if (!this.detail.token.user) {
        return false;
      }
      let user = this.detail.token.user.toLowerCase();
      if (!this.account) {
        return false;
      }
      let account = this.account.toLowerCase();
      if (user == account && this.detail.token.is_owner) {
        return true;
      }
      return false;
    },
    is_rent() {
      if (!this.detail.token.user) {
        return false;
      }
      let user = this.detail.token.user.toLowerCase();
      if (!this.account) {
        return false;
      }
      let account = this.account.toLowerCase();
      if (user == account && !this.detail.token.is_owner) {
        return true;
      }
      return false;
    },
    is_offer() {
      return this.offerData ? true : false;
    },
    timesTime() {
      return function (time) {
        return moment(time * 1000);
      };
    },
    transformTime() {
      return function (time) {
        let differTime = this.now - time;
        if (differTime >= 86400) {
          return Math.floor(differTime / 86400) + ' days';
        }
        if (differTime >= 3600) {
          return Math.floor(differTime / 3600) + ' hours';
        }
        if (differTime <= 0) {
          differTime = 1;
        }
        return Math.floor(differTime / 60) + ' minutes';
      };
    },
    endTime() {
      return function (time) {
        if (time) {
          return moment.unix(time).format('YYYY-MM-DD HH:mm:ss');
        }
      };
    },
  },
  watch: {
    account(val) {
      if (val) {
        this.getHasBalance();
        this.loadDetail();
      }
    },
    '$route.params.account': {
      handler() {
        this.loadDetail();
      },
      deep: true,
    },
  },
  mounted() {
    window.scrollTo(0, 0);
    this.loadDetail();
  },
  methods: {
    ...mapActions(['connected', 'switchNetwork']),
    timeToString(time) {
      time = parseInt(time);
      if (time === 0) {
        return '';
      }
      if (time <= 60) {
        return time + ' seconds';
      }
      if (time <= 3600) {
        return parseInt(time / 60) + ' Minus';
      }
      if (time <= 86400) {
        return parseInt(time / 3600) + ' Hours';
      }
      if (time <= 2 * 86400) {
        return parseInt(time / 86400) + ' Day';
      }
      return parseInt(time / 86400) + ' Days';
    },
    leftTime(time) {
      time = parseInt(time);
      let now = parseInt(Date.now() / 1000);
      if (time < now) {
        return '';
      }
      return this.timeToString(time - now);
    },
    async getHasBalance() {
      if (!this.account) {
        return;
      }
      if (!this.detail.market.pay_token) {
        return;
      }
      let price = this.detail.market.price;
      let balance = '0';
      if (this.detail.market.pay_token.toLowerCase() === constants.AddressZero.toLowerCase()) {
        balance = await nftSdk.getBalance(this.account);
      } else {
        balance = await nftSdk.getTokenBalance(this.detail.market.pay_token, this.account);
        balance = BigNumber(balance.toString()).div(BigNumber(10).pow(this.detail.market.pay_token_decimals)).toFixed(this.detail.market.pay_token_decimals);
      }
      if (BigNumber(price).gte(balance.toString())) {
        this.hasBalance = true;
      } else {
        this.hasBalance = false;
      }
    },
    async getRentalBalance() {
      // if (!['ERC-5187', 'ERC-4907'].includes(this.detail.collect.support_eip)) {
      //     return;
      // }
      // if (!this.account) {
      //     return;
      // }
      // if (this.detail.collect.support_eip === 'ERC-4907') {
      // }
      // let _balance = await nftSdk.get1155Balance(this.detail.collect.address, this.account, this.detail.token.token_id);
      // console.log({ _balance: _balance.toString() });
      // if (BigNumber(_balance.toString()).gt(0)) {
      //     this.hasBalance = true;
      // } else {
      //     this.hasBalance = false;
      // }
      // let rental_expire;
    },
    getUserOffer() {
      if (!this.account) return;
      userOffer({
        identify: this.detail.collect.identify,
        address: this.account,
        token_id: this.$route.params.id,
        owner: this.$route.params.owner,
      }).then(({ data, code }) => {
        if (code != 0) {
          return;
        }
        this.offerData = data;
      });
    },
    ipfs(uri) {
      if (!uri) {
        return '';
      }
      if (uri.substring(0, 4) != 'ipfs') {
        return uri;
      }
      let v1;
      let paths = uri.substring(7).split('/');
      if (paths[0].length == 46) {
        v1 = new CID(paths[0]).toV1().toString('base32');
      } else {
        v1 = uri;
      }
      let path = '';
      if (paths.length > 1) {
        path = '/' + paths.splice(1).join('/');
      }
      return `https://${v1}.ipfs.dweb.link${path}`;
    },
    loadDetail() {
      nftDetail({
        chain: this.$route.params.chain,
        contract_address: this.$route.params.contract,
        token_id: this.$route.params.id,
        owner: this.$route.params.owner,
      }).then(({ data, code }) => {
        if (code != 0) {
          return;
        }
        this.detail = data;
        if (this.detail.market.oid) {
          if ([1, 4].includes(this.detail.market.type)) {
            this.defaultActiveKey = '2';
          }
        }
        if (this.is_rent) {
          this.defaultActiveKey = '2';
        }
        this.issueTrade.price = this.detail.market.price || '';
        this.tabActiveKey = this.defaultActiveKey;
        this.issueTrade.pay_token = this.detail.pay_token[0].address;
        this.getUserOffer();
        this.getActivity();
        this.getOfferList();
        this.getHasBalance();
      });
    },
    nftTabChange(activeKey) {
      this.tabActiveKey = this.defaultActiveKey;
      if (this.tabActiveKey != activeKey) {
        if (activeKey == '1' || activeKey == '2') {
          if (this.detail.collect.support_eip == 'ERC-4907' || this.detail.collect.support_eip == 'ERC-5187') {
            if (!this.detail.market.oid && !this.is_rent) {
              this.tabActiveKey = activeKey;
              this.defaultActiveKey = activeKey;
              return;
            }
          }
          if (activeKey == '2') {
            this.$notification['warning']({
              message: 'Renting is unsupported for this NFT.',
            });
            return;
          }
          this.$notification['warning']({
            message: 'Not Released',
          });
        } else {
          this.$notification['warning']({
            message: 'Coming soon',
          });
        }
      }
    },
    setModal2Visible(modal2Visible) {
      this.modal2Visible = modal2Visible;
    },
    changeActivekey() {
      this.officeHeight = this.officeHeight ? false : true;
    },
    changeActivekey2() {
      this.informationHeight = this.informationHeight ? false : true;
    },
    changeActivekey3() {
      this.activityHeight = this.activityHeight ? false : true;
    },
    changeDomainVisible() {
      this.domainVisible = !this.domainVisible;
    },
    refresh() {
      this.$notification['success']({
        message: "We've queued this item for an update! Check back in a minute",
      });
      updateMeta({
        chain: this.$route.params.chain,
        contract_address: this.$route.params.contract,
        token_id: this.$route.params.id,
      }).then(({ code }) => {
        if (code != 0) {
          return;
        }
      });
    },
    doCopy() {
      const message = window.location.href;
      this.$copyText(message).then(
        () => {
          this.$notification['success']({
            message: 'Link copied!',
          });
        },
        () => {
          this.$notification['error']({
            message: 'Link copy failed',
          });
        }
      );
    },
    disabledDate(current) {
      return current && current < moment().endOf('day');
    },
    rangeHours(start, end, value, chooseday, day) {
      const result = [];
      if (chooseday === day) {
        for (let i = start; i < end; i++) {
          if (i < value) {
            result.push(i);
          }
        }
      }
      return result;
    },
    rangeMinutes(start, end, value, chooseday, day, chooseh, h) {
      const result = [];
      if (chooseday === day && chooseh === h) {
        for (let i = start; i < end; i++) {
          if (i < value) {
            result.push(i);
          }
        }
      }
      return result;
    },
    rangeSeconds(start, end, value, chooseday, day, chooseh, h, choosem, m) {
      const result = [];
      if (chooseday === day && chooseh === h && choosem === m) {
        for (let i = start; i < end; i++) {
          if (i < value) {
            result.push(i);
          }
        }
      }
      return result;
    },
    disabledDateTime(date) {
      const choosetime = moment(date).format('HH-mm-ss');
      const chooseday = parseInt(moment(date).format('YYYY-MM-DD').split('-')[2]);
      const chooseh = parseInt(choosetime.split('-')[0]);
      const choosem = parseInt(choosetime.split('-')[1]);
      const nowtime = moment().format('HH-mm-ss');
      const day = parseInt(moment().format('YYYY-MM-DD').split('-')[2]);
      const h = parseInt(nowtime.split('-')[0]);
      const m = parseInt(nowtime.split('-')[1]);
      const s = parseInt(nowtime.split('-')[2]);
      return {
        disabledHours: () => this.rangeHours(0, 24, h, chooseday, day),
        disabledMinutes: () => this.rangeMinutes(0, 60, m, chooseday, day, chooseh, h),
        disabledSeconds: () => this.rangeSeconds(0, 60, s, chooseday, day, chooseh, h, choosem, m),
      };
    },
    activityInfiniteOnLoad() {
      if (this.activity.loading) return;
      if (this.activity.list.length >= this.activity.pagination.total) {
        this.activity.busy = true;
        this.activity.loading = false;
        return;
      }
      this.activity.loading = true;
      this.getActivity(this.activity.list.length);
    },
    getActivity(skip) {
      if (!skip) {
        skip = 0;
      }
      activity({
        token_id: this.detail.token.token_id,
        identify: this.detail.collect.identify,
        type: this.activity.type,
        skip: 0,
        limit: 20,
      }).then(({ data, extra }) => {
        if (skip == 0) {
          this.activity.list = data;
        } else {
          this.activity.list = this.activity.list.concat(data);
        }
        this.activity.typeDict = extra.type_dict;
        this.activity.pagination.total = extra.total;
        this.activity.loading = false;
      });
    },
    onActivityChange() {
      this.getActivity();
    },
    offerInfiniteOnLoad() {
      if (this.offer.loading) return;
      if (this.offer.list.length >= this.offer.pagination.total) {
        this.offer.busy = true;
        this.offer.loading = false;
        return;
      }
      this.offer.loading = true;
      this.getOfferList(this.offer.list.length);
    },
    getOfferList(skip) {
      if (!skip) {
        skip = 0;
      }
      collectionOffer({
        collect_id: this.detail.collect.collect_id,
        token_id: this.detail.token.token_id,
        identify: this.detail.collect.identify,
        skip,
        limit: 20,
      }).then(({ data, extra }) => {
        if (skip == 0) {
          this.offer.list = data;
        } else {
          this.offer.list = this.offer.list.concat(data);
        }
        this.offer.pagination.total = extra.total;
        this.offer.loading = false;
      });
    },
    async clickCancel(oid) {
      if (!this.account) {
        let ret = await this.connected();
        if (!ret) {
          return;
        }
      }
      if (!this.isConnected) {
        await this.switchNetwork();
      }
      this.cancelLoading = true;
      try {
        const tx = await nftSdk.cancel(oid);
        await confirmTransaction(tx.hash);
        this.cancelLoading = false;
        this.$notification['success']({
          message: 'Transaction success',
        });
        this.loadDetail();
      } catch (err) {
        this.cancelLoading = false;
        if (err.code == 4001 || err.code == 'ACTION_REJECTED') {
          this.$notification['error']({
            message: 'Transcation cancelled',
          });
          return;
        }
        this.$notification['error']({
          message: 'Transcation failed',
        });
      } finally {
        this.modal2Visible = false;
        this.modal4Visible = false;
      }
    },
    async clickIssue(code) {
      if (this.connectedLoading) {
        return;
      }
      if (!this.account) {
        let ret = await this.connected();
        if (!ret) {
          return;
        }
      }
      if (!this.isConnected) {
        await this.switchNetwork();
      }
      if (!this.issueTrade.price) {
        this.$notification['error']({
          message: 'Price is empty',
        });
        return;
      }
      if (!this.issueTrade.end_time) {
        this.$notification['error']({
          message: 'Time Left is empty',
        });
        return;
      }
      if (!this.issueTrade.times && this.detail.collect.support_eip == 'ERC-1155') {
        this.$notification['error']({
          message: 'Trade Share is empty',
        });
        return;
      }
      if (code == 'rent') {
        if (!this.issueTrade.times && this.detail.collect.support_eip == 'ERC-5187') {
          this.$notification['error']({
            message: 'Rental Share is empty',
          });
          return;
        }
        if (!this.issueTrade.period) {
          this.$notification['error']({
            message: 'Rental Period is empty',
          });
          return;
        }
      }
      this.connectedLoading = true;
      let data = Buffer.from('');
      let type = 0;
      let is_provide_nft = this.is_owner;
      const AbiCoder = ethers.utils.AbiCoder;
      const abiCoder = new AbiCoder();
      switch (this.detail.collect.support_eip) {
        case 'ERC-1155':
          type = 2;
          data = abiCoder.encode(['uint256'], [this.issueTrade.times]);
          break;
        case 'ERC-4907':
          if (code == 'sale') {
            type = 0;
          } else {
            type = 1;
            data = abiCoder.encode(['uint256'], [BigNumber(this.issueTrade.period).multipliedBy(86400).toFixed(0)]);
          }
          break;
        case 'ERC-5187':
          if (code == 'sale') {
            type = 3;
          } else {
            type = 4;
            data = abiCoder.encode(['uint256', 'uint256'], [this.issueTrade.times, BigNumber(this.issueTrade.period).multipliedBy(86400).toFixed(0)]);
          }
          break;
      }
      let decimals = 18;
      for (let key in this.detail.pay_token) {
        if (this.detail.pay_token[key].address == this.issueTrade.pay_token) {
          decimals = this.detail.pay_token[key].decimals;
          break;
        }
      }
      const price = BigNumber(this.issueTrade.price).multipliedBy(BigNumber(10).exponentiatedBy(decimals)).toFixed(0);
      let eth_amount = 0;
      try {
        if (is_provide_nft) {
          const is_approved = await nftSdk.isApprovedForAll(this.detail.collect.address, this.account);
          if (!is_approved) {
            const tx = await nftSdk.setApprovalForAll(this.detail.collect.address, this.account);
            await nftSdk.waitForTransaction(tx.hash);
          }
        } else {
          if (this.issueTrade.pay_token.toLowerCase() != nftSdk.AddressZero.toLowerCase()) {
            let allowance = await nftSdk.allowance(this.issueTrade.pay_token, this.account, MARKET_CONTRACT);
            let _pay = BigNumber(this.issueTrade.price).multipliedBy(BigNumber(10).pow(decimals));
            if (BigNumber(allowance.toString()).lt(_pay)) {
              let tx = await nftSdk.approve(this.issueTrade.pay_token, MARKET_CONTRACT);
              await nftSdk.waitForTransaction(tx.hash);
            }
            let _balance = await nftSdk.getTokenBalance(this.issueTrade.pay_token, this.account);
            console.log({
              _balance: _balance.toString(),
              _pay: _pay.toFixed(0),
            });
            if (BigNumber(_balance.toString()).lt(_pay)) {
              this.$notification['error']({
                message: 'Insufficient balance',
              });
              return;
            }
          } else {
            eth_amount = price;
          }
        }
        console.log(JSON.stringify([
          this.detail.collect.address,
          this.detail.token.token_id,
          moment.utc(this.issueTrade.end_time).unix(),
          this.issueTrade.pay_token,
          price,
          type,
          data,
          is_provide_nft,
          eth_amount]));
        let tx = await nftSdk.make(
          this.detail.collect.address,
          this.detail.token.token_id,
          moment.utc(this.issueTrade.end_time).unix(),
          this.issueTrade.pay_token,
          price,
          type,
          data,
          is_provide_nft,
          eth_amount
        );
        await confirmTransaction(tx.hash);
        this.connectedLoading = false;
        this.$notification['success']({
          message: 'Transaction success',
        });
        this.loadDetail();
      } catch (err) {
        console.log(err);
        this.connectedLoading = false;
        if (err.code == 4001 || err.code == 'ACTION_REJECTED') {
          this.$notification['error']({
            message: 'Transcation cancelled',
          });
          return;
        }
        if (err.message && err.message.includes('insufficient funds')) {
          this.$notification['error']({
            message: 'Insufficient balance',
          });
          return;
        }
        this.$notification['error']({
          message: 'Transcation failed',
        });
      }
    },
    async clickMakeOffer(code) {
      if (this.connectedLoading) {
        return;
      }
      if (!this.account) {
        let ret = await this.connected();
        if (!ret) {
          return;
        }
      }
      if (!this.isConnected) {
        await this.switchNetwork();
      }
      if (!this.issueTrade.price) {
        this.$notification['error']({
          message: 'Price is empty',
        });
        return;
      }
      if (!this.issueTrade.times && this.detail.collect.support_eip == 'ERC-1155') {
        this.$notification['error']({
          message: 'Trade Share is empty',
        });
        return;
      }
      if (!this.issueTrade.validity_time) {
        this.$notification['error']({
          message: 'Validity of Offer is empty',
        });
        return;
      }
      if (code == 'rent' && this.detail.collect.support_eip == 'ERC-5187' && !this.issueTrade.times) {
        this.$notification['error']({
          message: 'Rental Share is empty',
        });
        return;
      }
      this.connectedLoading = true;
      let data = Buffer.from('');
      let is_provide_nft = false;
      const AbiCoder = ethers.utils.AbiCoder;
      const abiCoder = new AbiCoder();
      if (this.detail.market.type == 1) {
        data = abiCoder.encode(['uint256'], [this.detail.market.rental_period]);
      }
      if (this.detail.market.type == 2) {
        data = abiCoder.encode(['uint256'], [this.issueTrade.times]);
      }
      if (this.detail.market.type == 4) {
        data = abiCoder.encode(['uint256', 'uint256'], [this.issueTrade.times, this.detail.market.rental_period]);
      }
      let decimals = this.detail.market.pay_token_decimals;
      const price = BigNumber(this.issueTrade.price).multipliedBy(BigNumber(10).exponentiatedBy(decimals)).toFixed(0);
      const end_time = BigNumber(this.now).plus(BigNumber(this.issueTrade.validity_time).multipliedBy(86400)).toFixed(0);
      try {
        let eth_amount = 0;
        if (this.detail.market.pay_token.toLowerCase() != nftSdk.AddressZero.toLowerCase()) {
          let allowance = await nftSdk.allowance(this.detail.market.pay_token, this.account, MARKET_CONTRACT);
          let _pay = BigNumber(this.issueTrade.price).multipliedBy(BigNumber(10).exponentiatedBy(decimals));
          if (BigNumber(allowance.toString()).lt(_pay)) {
            let tx = await nftSdk.approve(this.detail.market.pay_token, MARKET_CONTRACT);
            await nftSdk.waitForTransaction(tx.hash);
          }
          let _balance = await nftSdk.getTokenBalance(this.detail.market.pay_token, this.account);
          console.log({
            _balance: _balance.toString(),
            _pay: _pay.toFixed(0),
          });
          if (BigNumber(_balance.toString()).lt(_pay)) {
            this.$notification['error']({
              message: 'Insufficient balance',
            });
            return;
          }
        } else {
          eth_amount = price;
        }
        let tx = await nftSdk.make(this.detail.collect.address, this.detail.token.token_id, end_time, this.detail.market.pay_token, price, this.detail.market.type, data, is_provide_nft, eth_amount);
        await confirmTransaction(tx.hash);
        this.$notification['success']({
          message: 'Transaction success',
        });
        this.loadDetail();
      } catch (err) {
        console.log(err);
        if (err.code == 4001 || err.code == 'ACTION_REJECTED') {
          this.$notification['error']({
            message: 'Transcation cancelled',
          });
          return;
        }
        if (err.message && err.message.includes('insufficient funds')) {
          this.$notification['error']({
            message: 'Insufficient balance',
          });
          return;
        }
        this.$notification['error']({
          message: 'Transcation failed',
        });
      } finally {
        this.connectedLoading = false;
        this.modal2Visible = false;
        this.modal4Visible = false;
      }
    },
    async clickTake() {
      console.log('clickTake');
      if (this.TakeLoading) {
        return;
      }
      if (!this.account) {
        let ret = await this.connected();
        if (!ret) {
          return;
        }
      }
      if (!this.isConnected) {
        await this.switchNetwork();
      }
      this.TakeLoading = true;
      try {
        let eth_amount = 0;
        if (this.detail.market.pay_token.toLowerCase() != nftSdk.AddressZero.toLowerCase()) {
          let allowance = await nftSdk.allowance(this.detail.market.pay_token, this.buyAddress || this.account, MARKET_CONTRACT);
          let _pay = BigNumber(this.detail.market.price).multipliedBy(BigNumber(10).pow(this.detail.market.pay_token_decimals));
          if (BigNumber(allowance.toString()).lt(_pay)) {
            let tx = await nftSdk.approve(this.detail.market.pay_token, MARKET_CONTRACT);
            await nftSdk.waitForTransaction(tx.hash);
          }
          let _balance = await nftSdk.getTokenBalance(this.detail.market.pay_token, this.account);
          console.log({
            _balance: _balance.toString(),
            _pay: _pay.toFixed(0),
          });
          if (BigNumber(_balance.toString()).lt(_pay)) {
            this.$notification['error']({
              message: 'Insufficient balance',
            });
            return;
          }
        } else {
          eth_amount = BigNumber(this.detail.market.price).multipliedBy(BigNumber(10).pow(this.detail.market.pay_token_decimals)).toFixed(0);
          let _balance = await nftSdk.getBalance(this.account);
          if (BigNumber(_balance.toString()).lt(this.detail.market.price)) {
            this.$notification['error']({
              message: 'Insufficient balance',
            });
            return;
          }
        }
        let tx = await nftSdk.take(this.detail.market.oid, this.buyAddress || this.account, eth_amount);
        await confirmTransaction(tx.hash);
        this.$notification['success']({
          message: 'Transaction success',
        });
        if (this.detail.collect.support_eip == 'ERC-1155' || this.detail.collect.support_eip == 'ERC-5187') {
          setTimeout(() => {
            this.$router.push(`/market/${this.$route.params.chain}/${this.$route.params.contract}/${this.$route.params.id}/${this.account}`);
          }, 4000);
        } else {
          this.loadDetail();
        }
      } catch (err) {
        console.log(err);
        if (err.code == 4001 || err.code == 'ACTION_REJECTED') {
          this.$notification['error']({
            message: 'Transcation cancelled',
          });
          return;
        }
        this.$notification['error']({
          message: 'Transcation failed',
        });
      } finally {
        this.TakeLoading = false;
        this.showBuyDialog = false;
        this.showRentDialog = false;
      }
    },
    async openOfferModal(code) {
      if (code == '2') {
        this.modal2Visible = true;
      }
      if (code == '4') {
        this.modal4Visible = true;
      }
      if (this.detail.market.pay_token.toLowerCase() != nftSdk.AddressZero.toLowerCase()) {
        let OfferBalance = await nftSdk.getTokenBalance(this.detail.market.pay_token, this.account);
        this.OfferBalance = BigNumber(OfferBalance.toString()).dividedBy(BigNumber(10).pow(this.detail.market.pay_token_decimals)).toFixed(0);
      } else {
        this.OfferBalance = await nftSdk.getBalance(this.account);
      }
    },
    async clickTransfer() {
      if (this.transferLoading) {
        return;
      }
      if (!this.account) {
        let ret = await this.connected();
        if (!ret) {
          return;
        }
      }
      if (!this.isConnected) {
        await this.switchNetwork();
      }
      let receiveAddress = this.receiveAddress;
      if (!(/^0x[a-fA-F0-9]{40}$/.test(receiveAddress) || /^[a-zA-Z0-9]+\.[a-zA-Z0-9]+$/.test(receiveAddress))) {
        return;
      }
      try {
        this.transferLoading = true;
        let tx;
        console.log(this.detail.collect.address, receiveAddress, this.detail.token.token_id);
        if (this.detail.collect.support_eip === 'ERC-1155' || this.detail.collect.support_eip === 'ERC-5187') {
          tx = await nftSdk.erc1155Transfer(this.detail.collect.address, this.account, receiveAddress, this.detail.token.token_id, this.detail.token.balance);
        } else {
          tx = await nftSdk.erc721Transfer(this.detail.collect.address, this.account, receiveAddress, this.detail.token.token_id);
        }
        await confirmTransaction(tx.hash);
        this.$notification['success']({
          message: 'Transaction success',
        });
        this.$router.push('/account/' + this.account);
      } catch (err) {
        console.log(err);
        if (err.code == 4001 || err.code == 'ACTION_REJECTED') {
          this.$notification['error']({
            message: 'Transcation cancelled',
          });
          return;
        }
        this.$notification['error']({
          message: 'Transcation failed',
        });
      } finally {
        this.transferLoading = false;
      }
    },
    linkAccount(address) {
      this.$router.push('/account/' + address);
    },
    linkDetail(address) {
      let url = `/market/${this.$route.params.chain}/${this.$route.params.contract}/${this.$route.params.id}/${address}`;
      this.$router.push(url);
      this.ownerVisible = false;
    },
    goSearchTrade() {
      this.$router.push({ name: 'search', query: { tradingMethods: this.detail.collect.type } });
    },
    goSearchcategory() {
      this.$router.push({ name: 'search', query: { categories: this.detail.collect.category } });
    },
    goSearchstandard() {
      this.$router.push({ name: 'search', query: { standards: this.detail.collect.support_eip } });
    },
    goCollection() {
      this.$router.push('/collection/' + this.detail.collect.identify);
    },
  },
};
</script>
<style lang="scss">
.ant-popover-placement-top {
  padding: 0;
  .ant-popover-arrow {
    display: none;
  }
  .ant-popover-inner {
    background-color: transparent;
    box-shadow: none;
    border-radius: 0;
    padding: 20px 0 0 140px;
  }
  .ant-popover-inner-content {
    // margin:18px 0 0 128px;
    background-color: #093f3b;
    padding: 6px 4px;
    font-size: 12px;
    font-family: 'SFPixelate-Bold';
    color: #c0d5d1;
    line-height: 14px;
  }
}
.day_select {
  background-color: #01c67f;
  .ant-select-dropdown-menu-item-selected {
    background-color: #01c67f;
    color: #000000;
  }
  .ant-select-dropdown-menu-item {
    color: #000000;
    border-bottom: 1px solid #0b2e33;
    &:last-child {
      border: none;
    }
  }
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #01c67f;
  }
  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background-color: #01c67f;
  }
}
.price_type {
  background-color: #09282c;
  .ant-select-dropdown-menu-item-selected,
  .ant-select-dropdown-menu-item {
    background-color: #09282c;
    color: #ffffff;
    font-family: 'SFPixelate-Bold';
    font-size: 12px;
    border-bottom: 1px solid #00c67f;
    &:last-child {
      border: none;
    }
  }
  .ant-select-dropdown-menu-item:hover:not(.ant-select-dropdown-menu-item-disabled) {
    background: none;
  }
  .ant-select-dropdown-menu-item-active:not(.ant-select-dropdown-menu-item-disabled) {
    background: none;
  }
}
.owner-modal {
  .ant-modal-content {
    background: #0b2d33;
    box-shadow: none;
    .ant-modal-close-x {
      color: #ffffff;
    }
    .ant-modal-header {
      background: #0b2d33;
      border: none;
      .ant-modal-title {
        color: #ffffff;
      }
    }
    .ant-modal-body {
      padding-top: 0;
      p {
        color: #01aa6f;
        padding: 8px 0;
        border-bottom: 1px solid #074d40;
        cursor: pointer;
        &:last-child {
          border: none;
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .ant-popover-placement-top {
    .ant-popover-inner {
      padding: 0.4rem 0 0 1.2rem;
    }
    .ant-popover-inner-content {
      padding: 0.12rem 0.08rem;
      font-size: 0.24rem;
      line-height: 0.28rem;
    }
  }
}
</style>
<style lang="scss" scoped>
.allTips {
  width: 1200px;
  margin: 52px auto 0;
  height: 70px;
  background: url('~@/assets/images/market-detail/allTips_bg.svg') no-repeat;
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-family: 'SFPixelate-Bold';
  padding: 14px 30px 14px 24px;
  p {
    font-size: 16px;
    color: #ffffff;
    line-height: 22px;
  }
  button {
    width: 113px;
    height: 41px;
    font-size: 16px;
    color: #000000;
    border: none;
    background: url('~@/assets/images/market-detail/cancel.svg') no-repeat;
  }
}
.detail-box {
  width: 1200px;
  margin: 74px auto 0;
  display: flex;
  justify-content: space-between;
  .detail-left {
    width: 610px;
    .nft_name {
      font-size: 30px;
      font-family: 'SFPixelate-Bold';
      color: #ffffff;
      line-height: 32px;
      .domain_box {
        display: none;
      }
    }
    .series_box {
      margin-top: 30px;
      display: flex;
      align-items: center;
      font-family: 'SFPixelate-Bold';
      p {
        font-size: 12px;
        color: #537171;
        line-height: 14px;
        margin-right: 5px;
      }
      span {
        font-size: 12px;
        color: #072a2c;
        line-height: 14px;
        padding: 1px 4px;
        margin-left: 15px;
        background-color: #de8900;
        cursor: pointer;
      }
    }
    .address_box {
      margin-top: 30px;
      display: flex;
      align-items: center;
      font-size: 12px;
      font-family: 'SFPixelate-Bold';
      line-height: 14px;
      p {
        color: #537171;
        &:last-of-type {
          display: flex;
          align-items: center;
          &::before {
            content: '';
            width: 14px;
            height: 10px;
            background: url('~@/assets/images/market-detail/eye.svg') no-repeat center;
            display: inline-block;
            margin-right: 4px;
          }
        }
      }
      span {
        color: #01c67f;
        margin: 0 35px 0 15px;
        cursor: pointer;
      }
    }
    .commodity_box {
      display: none;
    }
    .buy_box {
      margin-top: 42px;
      height: 293px;
      background: url('~@/assets/images/market-detail/buy_bg.svg') no-repeat;
      :deep(.ant-calendar-picker) {
        width: 100%;
        .ant-calendar-picker-clear,
        .ant-calendar-picker-icon {
          display: none;
        }
      }
      :deep(.ant-tabs) {
        .ant-tabs-bar {
          border: none;
          padding-left: 10px;
          margin: 0;
          .ant-tabs-tab {
            background-color: transparent;
            border: none;
            font-size: 14px;
            font-family: 'SFPixelate-Bold';
            color: #008b56;
            line-height: 16px;
            margin-left: 13px;
            padding: 14px 0;
            height: auto;
          }
          .ant-tabs-tab-active {
            color: #01c67f;
          }
        }
        .ant-tabs-content {
          margin-top: 28px;
          padding-left: 24px;
          .buy_main {
            font-family: 'SFPixelate-Bold';
            p {
              font-size: 12px;
              color: #537171;
              line-height: 14px;
            }
            .price_box {
              display: flex;
              align-items: center;
              margin-top: 12px;
              img {
                width: 24px;
                height: 24px;
              }
              h4 {
                font-size: 30px;
                color: #ffffff;
                margin: 0 8px;
                line-height: 32px;
              }
              span {
                font-size: 12px;
                color: #c0d5d1;
                line-height: 14px;
                margin-top: 9px;
              }
            }
            .time_box {
              display: flex;
              align-items: center;
              margin-top: 26px;
              font-family: 'SFPixelate-Bold';
              h4 {
                font-size: 12px;
                color: #537171;
                line-height: 14px;
              }
              p {
                font-size: 14px;
                color: #ffffff;
                line-height: 16px;
                margin-left: 16px;
              }
            }
            .button_box {
              display: flex;
              margin-top: 40px;
              .buy_button,
              .make_button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 164px;
                height: 42px;
                background: url('~@/assets/images/market-detail/buy_btn.svg') no-repeat center;
                font-size: 16px;
                color: #000000;
                line-height: 17px;
                cursor: pointer;
              }
              .make_button {
                background: url('~@/assets/images/market-detail/make_btn.svg') no-repeat center;
                margin-left: 42px;
                color: #01c67f;
              }
              .buy_button_disabled {
                background: rgba(51, 51, 51, 0.6);
              }
            }
            .user_trade {
              .user_trade_ul {
                display: flex;
              }
              .ant-select {
                width: 100%;
                padding: 10px 12px;
                .ant-select-selection {
                  background-color: transparent;
                  border: none;
                  height: auto;
                  box-shadow: none;
                  &:active {
                    box-shadow: none !important;
                  }
                  .ant-select-selection__rendered {
                    margin: 0;
                    font-size: 16px;
                    font-family: 'SFPixelate-Bold';
                    color: #ffffff;
                    line-height: 17px;
                  }
                }
                .ant-select-arrow {
                  margin-top: -7px;
                  right: 0px;
                  img {
                    width: 14px;
                    height: 14px;
                  }
                }
              }
              h5 {
                font-size: 12px;
                font-family: 'SFPixelate-Bold';
                color: #537171;
                line-height: 14px;
                margin-bottom: 8px;
              }
              .user_price {
                display: flex;
                align-items: center;
                .price_ipt {
                  .ant-input-number {
                    width: 190px;
                    height: 37px;
                    background: url('~@/assets/images/market-detail/priceIpt_mo.svg') no-repeat;
                    border: none;
                    box-shadow: none;
                    .ant-input-number-handler-wrap {
                      display: none;
                    }
                    input {
                      height: 37px;
                      padding: 0 12px;
                      color: #ffffff;
                      font-size: 16px;
                    }
                  }
                }
                .price_type {
                  width: 109px;
                  height: 37px;
                  margin: 0 12px;
                  background: url('~@/assets/images/market-detail/price_type.svg') no-repeat;
                }
                span {
                  font-size: 12px;
                  color: #c0d5d1;
                  line-height: 14px;
                }
              }
              .price_box {
                img {
                  width: 18px;
                  height: 18px;
                }
                h4 {
                  font-size: 24px;
                  line-height: 26px;
                }
                span {
                  font-size: 12px;
                  line-height: 14px;
                }
              }
              .user_time {
                margin-top: 25px;
                p {
                  font-size: 12px;
                  color: #537171;
                  line-height: 14px;
                  margin-bottom: 5px;
                  span {
                    font-size: 14px;
                    color: #ffffff;
                    line-height: 16px;
                    margin-left: 6px;
                  }
                }
                .time_container {
                  width: 331px;
                  height: 37px;
                  background: url('~@/assets/images/market-detail/user_time.svg') no-repeat;
                  :deep(.ant-calendar-picker) {
                    width: 100%;
                    .ant-calendar-picker-clear,
                    ant-calendar-picker-icon {
                      display: none;
                    }
                  }
                }
                .time_containerNo {
                  background: transparent;
                  background: url('~@/assets/images/market-detail/user_time2.svg') no-repeat;
                  span {
                    height: 36px;
                    line-height: 36px;
                    padding-left: 12px;
                    font-size: 14px;
                    color: rgba(255, 255, 255, 0.5);
                  }
                  .ant-select {
                    img {
                      display: none;
                    }
                  }
                }
              }
              .lssue {
                margin-top: 21px;
                button {
                  width: 562px;
                  height: 41px;
                  border: none;
                  box-shadow: none;
                  background: url('~@/assets/images/market-detail/issue_btn.svg') no-repeat;
                  font-size: 16px;
                  color: #000000;
                  &:after,
                  &:before {
                    display: none;
                  }
                }
                button[disabled] {
                  background: url('~@/assets/images/market-detail/issue_btn2.svg') no-repeat;
                }
              }
              .cancel_offer_btn {
                margin-top: 21px;
                button {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 164px;
                  height: 42px;
                  border: none;
                  background: url('~@/assets/images/market-detail/make_btn.svg') no-repeat center;
                  font-size: 16px;
                  color: #01c67f;
                  line-height: 17px;
                  cursor: pointer;
                }
              }
            }
          }
          .Rental_main {
            font-family: 'SFPixelate-Bold';
            ul {
              display: flex;
              li {
                font-family: 'SFPixelate-Bold';
                margin-right: 46px;
                &:nth-child(2) {
                  margin-right: 32px;
                }
                &:nth-child(3) {
                  margin-right: 0;
                }
                p {
                  font-size: 12px;
                  color: #537171;
                  line-height: 14px;
                  margin-bottom: 16px;
                }
                span {
                  font-size: 14px;
                  color: #c0d5d1;
                  line-height: 14px;
                  margin-top: 8px;
                  display: inline-block;
                }
                .flex {
                  display: flex;
                  align-items: center;
                  img {
                    width: 18px;
                    height: 18px;
                  }
                  span {
                    font-size: 24px;
                    color: #ffffff;
                    line-height: 26px;
                    margin: 0 12px 0 8px;
                  }
                  i {
                    font-size: 12px;
                    color: #c0d5d1;
                    line-height: 14px;
                    margin-top: 5px;
                  }
                }
              }
            }
            .time_box {
              display: flex;
              align-items: center;
              margin-top: 28px;
              h4 {
                font-size: 12px;
                color: #537171;
                line-height: 14px;
              }
              p {
                font-size: 14px;
                color: #ffffff;
                line-height: 16px;
                margin-left: 16px;
              }
            }
            .button_box {
              display: flex;
              margin-top: 40px;
              .buy_button,
              .make_button {
                display: flex;
                justify-content: center;
                align-items: center;
                width: 164px;
                height: 42px;
                background: url('~@/assets/images/market-detail/buy_btn.svg') no-repeat center;
                font-size: 16px;
                color: #000000;
                line-height: 17px;
                cursor: pointer;
              }
              .make_button {
                background: url('~@/assets/images/market-detail/make_btn.svg') no-repeat center;
                margin-left: 42px;
                color: #01c67f;
              }
              .buy_button_disabled {
                background: rgba(51, 51, 51, 0.6);
              }
            }
            .user_rental {
              .ant-select {
                width: 100%;
                padding: 10px 12px;
                .ant-select-selection {
                  background-color: transparent;
                  border: none;
                  height: auto;
                  box-shadow: none;
                  &:active {
                    box-shadow: none !important;
                  }
                  .ant-select-selection__rendered {
                    margin: 0;
                    font-size: 16px;
                    font-family: 'SFPixelate-Bold';
                    color: #ffffff;
                    line-height: 17px;
                  }
                }
                .ant-select-arrow {
                  margin-top: -7px;
                  right: 0px;
                  img {
                    width: 14px;
                    height: 14px;
                  }
                }
              }
              .user_price {
                display: flex;
                align-items: center;
                display: flex;
                align-items: flex-end;
                flex-wrap: wrap;
                h5 {
                  font-size: 12px;
                  font-family: 'SFPixelate-Bold';
                  color: #537171;
                  line-height: 14px;
                  margin-bottom: 8px;
                }
                .price_ipt {
                  .ant-input-number {
                    width: 121px;
                    height: 37px;
                    background: url('~@/assets/images/market-detail/price_ipt2.svg') no-repeat;
                    border: none;
                    box-shadow: none;
                    .ant-input-number-handler-wrap {
                      display: none;
                    }
                    input {
                      height: 37px;
                      padding: 0 12px;
                      color: #ffffff;
                      font-size: 16px;
                    }
                  }
                }
                .price_type {
                  width: 109px;
                  height: 37px;
                  margin: 0 12px;
                  background: url('~@/assets/images/market-detail/price_type.svg') no-repeat;
                }
                span {
                  display: inline-block;
                  font-size: 12px;
                  color: #c0d5d1;
                  line-height: 14px;
                  margin-bottom: 12px;
                }
                .rental_time {
                  margin: 0 16px;
                  .rentalTime_box {
                    width: 102px;
                    height: 37px;
                    background: url('~@/assets/images/market-detail/rental_time.svg') no-repeat;
                  }
                }
                .period_box {
                  input {
                    width: 121px;
                    height: 37px;
                    background: url('~@/assets/images/market-detail/price_ipt2.svg') no-repeat;
                    border: none;
                    border-radius: 0;
                    padding: 10px 12px;
                    color: #ffffff;
                    font-size: 16px;
                  }
                }
              }
              .user_time {
                margin-top: 25px;
                p {
                  font-size: 12px;
                  color: #537171;
                  line-height: 14px;
                  margin-bottom: 5px;
                  span {
                    font-size: 14px;
                    color: #ffffff;
                    line-height: 16px;
                    margin-left: 6px;
                    margin: 0;
                  }
                }
                .time_container {
                  width: 331px;
                  height: 37px;
                  background: url('~@/assets/images/market-detail/user_time.svg') no-repeat;
                }
                .time_containerNo {
                  background: transparent;
                  background: url('~@/assets/images/market-detail/user_time2.svg') no-repeat;
                  span {
                    height: 36px;
                    line-height: 36px;
                    padding-left: 12px;
                    font-size: 14px;
                    color: rgba(255, 255, 255, 0.5);
                  }
                  .ant-select {
                    img {
                      display: none;
                    }
                  }
                }
              }
              .lssue {
                margin-top: 21px;
                button {
                  width: 562px;
                  height: 41px;
                  border: none;
                  box-shadow: none;
                  background: url('~@/assets/images/market-detail/issue_btn.svg') no-repeat;
                  font-size: 16px;
                  color: #000000;
                  &:after,
                  &:before {
                    display: none;
                  }
                }
                button[disabled] {
                  background: url('~@/assets/images/market-detail/issue_btn2.svg') no-repeat;
                }
              }
              .cancel_offer_btn {
                margin-top: 21px;
                button {
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  width: 164px;
                  height: 42px;
                  border: none;
                  background: url('~@/assets/images/market-detail/make_btn.svg') no-repeat center;
                  font-size: 16px;
                  color: #01c67f;
                  line-height: 17px;
                  cursor: pointer;
                }
              }
            }
          }
        }
      }
    }
    .office_box {
      height: 427px;
      margin-top: 36px;
      margin-bottom: 75px;
      background: url('~@/assets/images/market-detail/office_bg.svg') no-repeat;
      position: relative;
      :deep(.ant-list-items) {
        margin: 0;
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        .ant-list-item {
          border: none;
          margin: 0;
          padding: 0;
        }
      }
      :deep(.ant-collapse) {
        background-color: transparent;
        border: none;
        .ant-collapse-item {
          border: none;
          .ant-collapse-header {
            padding-top: 14px;
            padding-left: 24px;
            font-size: 14px;
            font-family: 'SFPixelate-Bold';
            color: #ffffff;
            line-height: 16px;
            img {
              transition: all 0.3s ease 0s;
              margin-top: -4px;
              right: 24px;
            }
          }
          .ant-collapse-content {
            background-color: transparent;
            border: none;
            .ant-collapse-content-box {
              padding: 0;
              padding: 0 1.5px;
              overflow: hidden;
              table {
                width: 100%;
                padding-left: 24px;
                .title {
                  background-color: #09282c;
                  padding: 8px 1px;
                  padding-left: 24px;
                  border: none;
                  td {
                    color: #537171;
                  }
                }
                .table_main {
                  height: 341px;
                  overflow-y: auto;
                  &::-webkit-scrollbar {
                    width: 2px;
                    height: 36px;
                  }
                  &::-webkit-scrollbar-thumb {
                    background-color: #039564;
                    border-radius: 12px;
                  }
                  &::-webkit-scrollbar-track {
                    display: none;
                  }
                }
                .no_offers {
                  height: 330px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  p {
                    width: 200px;
                    height: 127px;
                    background: url('~@/assets/images/market-detail/no_offers.svg') no-repeat center;
                    font-size: 12px;
                    font-family: 'SFPixelate-Bold';
                    padding: 49px 0 0 41px;
                    color: #ffffff;
                    line-height: 14px;
                    text-shadow: 0 2px 0 rgba(0, 0, 0, 0.5);
                  }
                }
                tr {
                  display: block;
                  width: 100%;
                  padding: 23px 0;
                  padding-left: 24px;
                  border-bottom: 1px solid #2d483e;
                  display: flex;
                  td {
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    font-family: 'SFPixelate-Bold';
                    &:nth-child(1) {
                      width: 140px;
                      margin-right: 16px;
                      p {
                        margin: 0 4px;
                      }
                    }
                    &:nth-child(2) {
                      width: 120px;
                      margin-right: 22px;
                    }
                    &:nth-child(3) {
                      width: 71px;
                      margin-right: 69px;
                    }
                    &:nth-child(4) {
                      width: 120px;
                      p {
                        color: #01c67f;
                      }
                    }
                    img {
                      width: 16px;
                      height: 16px;
                    }
                    p {
                      color: #ffffff;
                    }
                    span {
                      color: #486d6b;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .officeHeight {
      height: 42px !important;
    }
  }
  .detail-right {
    width: 540px;
    .right_top {
      width: 100%;
      height: 40px;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      a {
        width: 40px;
        height: 40px;
        margin-right: 24px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: url('~@/assets/images/market-detail/web_bg.svg') no-repeat;
        &:hover {
          background: url('~@/assets/images/market-detail/web_bg_hover.svg') no-repeat;
        }
        &:first-child {
          background: url('~@/assets/images/market-detail/Reload_bg.svg') no-repeat center;
          &:hover {
            background: url('~@/assets/images/market-detail/Reload_bg_hover.svg') no-repeat;
          }
        }
        &:last-child {
          background: url('~@/assets/images/market-detail/share_bg.svg') no-repeat center;
          margin-right: 17px;
          &:hover {
            background: url('~@/assets/images/market-detail/share_bg_hover.svg') no-repeat;
          }
        }
        img {
          width: 24px;
          height: 24px;
        }
      }
    }
    .commodity_box {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 540px;
      height: 540px;
      margin-top: 12px;
      background: url('~@/assets/images/market-detail/commodity_bg.svg') no-repeat center;
      cursor: pointer;
      img {
        width: 480px;
        height: 480px;
      }
    }
    .information_box {
      margin-top: 36px;
      margin-bottom: 75px;
      width: 540px;
      height: 290px;
      background: url('~@/assets/images/market-detail/information_bg.svg') no-repeat;
      :deep(.ant-collapse) {
        background-color: transparent;
        border: none;
        .ant-collapse-item {
          border: none;
          .ant-collapse-header {
            padding-top: 14px;
            padding-left: 24px;
            font-size: 14px;
            font-family: 'SFPixelate-Bold';
            color: #ffffff;
            line-height: 16px;
            img {
              transition: all 0.3s ease 0s;
              margin-top: -4px;
              right: 24px;
            }
          }
          .ant-collapse-content {
            background-color: transparent;
            border: none;
            .ant-collapse-content-box {
              padding: 0;
              padding-left: 24px;
              padding-top: 27px;
              padding: 27px 24px 0;
              ul {
                li {
                  margin-bottom: 16px;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  font-size: 12px;
                  font-family: 'SFPixelate-Bold';
                  line-height: 14px;
                  p {
                    color: #537171;
                  }
                  a {
                    color: #01c67f;
                  }
                  span {
                    color: #ffffff;
                  }
                }
                .fee {
                  p {
                    display: flex;
                    align-items: center;
                    i {
                      display: inline-block;
                      width: 16px;
                      height: 16px;
                      background: url('~@/assets/images/market-detail/fee_ico.svg') no-repeat center;
                      margin-left: 8px;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
    .informationHeight {
      height: 42px !important;
    }
  }
}
.activity_box {
  overflow: hidden;
  width: 1200px;
  margin: 0 auto;
  margin-bottom: 144px;
  height: 517px;
  background: url('~@/assets/images/market-detail/activity_box.svg') no-repeat;
  :deep(.ant-collapse) {
    background-color: transparent;
    border: none;
    .ant-collapse-item {
      border: none;
      .ant-collapse-header {
        padding-top: 14px;
        padding-left: 24px;
        font-size: 14px;
        font-family: 'SFPixelate-Bold';
        color: #ffffff;
        line-height: 16px;
        img {
          transition: all 0.3s ease 0s;
          margin-top: -4px;
          right: 24px;
        }
      }
      .ant-collapse-content {
        background-color: transparent;
        border: none;
        .ant-collapse-content-box {
          padding: 0;
          padding: 0 1.5px;
          overflow: hidden;
        }
      }
    }
  }
  .lable_box {
    :deep(.ant-radio-group-default) {
      display: flex;
      padding: 12px 0;
      padding-left: 24px;
      border-bottom: 1px solid #2d483e;
      .ant-radio-button-wrapper {
        width: auto;
        padding: 0;
        background-color: transparent;
        border: 1px solid #de8900;
        border-radius: 0;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 24px;
        span:last-of-type {
          font-size: 12px;
          font-family: 'SFPixelate-Bold';
          color: #de8900;
          line-height: 14px;
          padding: 6px 14px;
        }
        &:not(:first-child)::before {
          display: none;
        }
      }
      .ant-radio-button-wrapper-checked {
        background-color: #de8900;
        span:last-of-type {
          color: #052a2c;
        }
      }
    }
  }
  .activity_main {
    height: 400px;
    overflow-y: auto;
    :deep(.ant-list-items) {
      .ant-list-item {
        border: none;
        margin: 0;
        padding: 0;
      }
    }
    &::-webkit-scrollbar {
      width: 2px;
      height: 2px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #039564;
      border-radius: 12px;
    }
    &::-webkit-scrollbar-track {
      display: none;
    }
    .activity-info {
      width: 100%;
      border-bottom: 1px solid #2d483e;
      display: flex;
      padding: 16px 12px 16px 0;
      .user_information {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      .user_img {
        width: 42px;
        height: 42px;
        margin: 0 20px 0 24px;
        background-color: #000000;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .user_name {
        font-size: 12px;
        font-family: 'SFPixelate-Bold';
        line-height: 14px;
        display: flex;
        margin-bottom: 12px;
        p {
          color: #ffffff;
        }
        span {
          padding: 0 6px;
          background-color: #de8900;
          margin-left: 10px;
        }
      }
      .right_box {
        display: flex;
        align-items: center;
        .user_from {
          display: flex;
          align-items: center;
          font-size: 12px;
          font-family: 'SFPixelate-Bold';
          color: #517171;
          line-height: 14px;
          p {
            color: #01aa6f;
            margin: 0 26px;
          }
          span {
            padding-bottom: 1px;
            border-bottom: 1px solid #00ab6d;
            :deep(.ant-btn) {
              background: transparent;
              border: none;
              height: auto;
              padding: 0;
              color: #00ab6d;
              font-size: 12px;
              font-family: 'SFPixelate-Bold';
              line-height: 14px;
            }
          }
          a {
            width: 16px;
            height: 16px;
            background: url('~@/assets/images/market-detail/share_ico.svg') no-repeat center;
            margin-left: 10px;
          }
        }
      }
      .price_box {
        display: flex;
        align-items: center;
        font-size: 12px;
        font-family: 'SFPixelate-Bold';
        line-height: 14px;
        p {
          color: #ffffff;
          margin: 0 10px;
        }
        img {
          width: 14px;
          height: 14px;
        }
        span {
          color: #bfd5d1;
        }
      }
      .rent-box {
        p {
          color: #ffffff;
          margin-left: 8px;
          font-size: 12px;
          font-family: 'SFPixelate-Bold';
        }
      }
    }
  }
}
.activityHeight {
  height: 42px;
}
.buyNow,
.make_box {
  :deep(.ant-modal-content) {
    min-height: 391px;
    background: url('~@/assets/images/market/authorization_bg.svg') no-repeat;
    box-shadow: none;
    border-radius: 0;
    padding: 46px 40px 66px;
    .ant-modal-close-x {
      display: none;
    }
    .ant-modal-body {
      padding: 0;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h4 {
          font-size: 18px;
          font-family: 'SFPixelate-Bold';
          color: #ffffff;
          line-height: 20px;
        }
        img {
          cursor: pointer;
          width: 18px;
          height: 18px;
        }
      }
      .information {
        display: flex;
        padding: 43px 0 28px;
        border-bottom: 1px solid #046e4e;
        .commodity {
          width: 80px;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: url('~@/assets/images/market-detail/commodity_bg2.svg') no-repeat center;
          img {
            width: 72px;
            height: 72px;
          }
        }
        .information_main {
          width: calc(100% - 107px);
          display: flex;
          justify-content: space-between;
          margin-left: 27px;
          .main_left {
            font-size: 14px;
            font-family: 'SFPixelate-Bold';
            font-weight: bold;
            color: #537171;
            line-height: 16px;
            span {
              color: #ffffff;
              margin: 14px 0;
              display: inline-block;
            }
            .fee {
              display: flex;
              align-items: center;
              i {
                width: 16px;
                height: 16px;
                margin-left: 8px;
                background: url('~@/assets/images/market-detail/fee_ico.svg') no-repeat center;
              }
            }
          }
          .main_right {
            .price {
              display: flex;
              align-items: center;
              font-family: 'SFPixelate-Bold';
              img {
                width: 18px;
                height: 18px;
              }
              p {
                font-size: 24px;
                color: #ffffff;
                line-height: 26px;
                margin: 0 12px 0 8px;
              }
              i {
                font-size: 12px;
                color: #c0d5d1;
                line-height: 14px;
                margin-top: 3px;
              }
            }
            span {
              font-size: 14px;
              font-family: 'SFPixelate-Bold';
              color: #ffffff;
              line-height: 16px;
              text-align: right;
              display: block;
              margin-top: 27px;
            }
          }
        }
      }
      .ant-collapse {
        background-color: transparent;
        border: none;
        border-radius: 0;
        .ant-collapse-item {
          border: none;
        }
        .ant-collapse-header {
          padding: 16px 0;
          font-size: 16px;
          font-family: 'SFPixelate-Bold';
          color: #ffffff;
          line-height: 17px;
          img {
            transition: all 0.3s ease 0s;
            margin-top: -4px;
            right: 0;
          }
        }
        .ant-collapse-content {
          border: none;
          background-color: transparent;
          border-radius: 0;
          .ant-collapse-content-box {
            padding: 0;
          }
          .wallet_input {
            width: 100%;
            height: 40px;
            border: none;
            padding: 12px 24px;
            background: url('~@/assets/images/market-detail/wallet_input.svg') no-repeat;
            color: #ffffff;
            font-size: 16px;
            font-family: 'SFPixelate-Bold';
            &::placeholder {
              font-size: 16px;
              color: #527171;
              line-height: 16px;
            }
          }
          .tips {
            font-size: 12px;
            font-family: 'SFPixelate-Bold';
            color: #537171;
            line-height: 18px;
            margin: 12px 0 30px 0;
            display: block;
          }
        }
      }
      .btn_buy {
        width: 100%;
        height: 41px;
        border: none;
        background: url('~@/assets/images/market-detail/btn_buy.svg') no-repeat center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-family: 'SFPixelate-Bold';
        color: #000000;
      }
    }
  }
  &-collapse {
    :deep(.ant-modal-content) {
      height: auto;
      background: url('~@/assets/images/market-detail/buyNow_bg.svg') no-repeat;
    }
  }
}
.make_box {
  :deep(.ant-modal-content) {
    background: url('~@/assets/images/market-detail/rent_make.svg') no-repeat center top;
    border-bottom: 1px solid #048a61;
    .balance_box {
      margin: 16px 0;
      display: flex;
      justify-content: space-between;
      align-items: center;
      font-size: 14px;
      font-family: 'SFPixelate-Bold';
      color: #537171;
      line-height: 16px;
      span {
        color: #ffffff;
      }
    }
    .balance_ipt {
      width: 476px;
      height: 40px;
      background: url('~@/assets/images/market-detail/balance_ipt.svg') no-repeat;
      border: none;
      box-shadow: none;
      .ant-input-number-handler-wrap {
        display: none;
      }
      input {
        padding-left: 24px;
        height: 40px;
        font-size: 14px;
        font-family: 'SFPixelate-Bold';
        color: #ffffff;
        &::placeholder {
          color: #537171;
        }
      }
    }
    .eth {
      display: inline-block;
      font-size: 16px;
      font-family: 'SFPixelate-Bold';
      color: #ffffff;
      line-height: 17px;
      margin-left: 8px;
    }
    .title_day {
      margin: 26px 0 16px;
      display: inline-block;
      font-size: 14px;
      font-family: 'SFPixelate-Bold';
      color: #537171;
      line-height: 16px;
    }
    .day_main {
      width: 100%;
      height: 40px;
      .ant-select {
        width: 100%;
        .ant-select-selection {
          height: 40px;
          background-color: transparent;
          border: none;
          padding: 12px 32px 12px 24px;
          display: flex;
          justify-content: space-between;
          align-items: center;
          background: url('~@/assets/images/market-detail/day_bg.svg') no-repeat;
          .ant-select-selection__rendered {
            margin: 0;
            padding: 0;
            position: static;
            margin: 0;
            padding: 0;
            &::after {
              display: none;
            }
            .ant-select-selection-selected-value {
              float: none;
              font-size: 14px;
              font-family: 'SFPixelate-Bold';
              color: #ffffff;
              line-height: 16px;
            }
          }
        }
        .ant-select-arrow {
          position: static;
          width: 14px;
          height: 14px;
          margin: 0;
        }
      }
      .ant-select-focused,
      .ant-select-open {
        .ant-select-selection {
          box-shadow: none;
        }
      }
    }
    .make_btn {
      width: 100%;
      height: 41px;
      margin-top: 32px;
      border: none;
      background: url('~@/assets/images/market-detail/bt_mate.svg') no-repeat;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 16px;
      font-family: 'SFPixelate-Bold';
      color: #000000;
    }
    .price_box {
      display: flex;
      align-items: center;
      font-family: 'SFPixelate-Bold';
      height: 40px;
      img {
        width: 18px;
        height: 18px;
      }
      h4 {
        font-size: 24px;
        line-height: 26px;
        color: #ffffff;
        margin: 0 12px 0 8px;
      }
      span {
        margin-top: 5px;
        font-size: 12px;
        line-height: 14px;
        color: #ffffff;
      }
    }
    .user_time {
      justify-content: flex-start;
      .time_container {
        background: #09282c;
        color: rgba(255, 255, 255, 0.6);
        height: 40px;
        line-height: 40px;
        padding-left: 24px;
        font-family: 'SFPixelate-Bold';
      }
    }
    .offer_btn {
      display: flex;
      justify-content: center;
      align-items: center;
      button {
        width: 164px;
        height: 42px;
        margin-top: 32px;
        border: none;
        background: url('~@/assets/images/market-detail/make_btn.svg') no-repeat center;
        font-size: 16px;
        font-family: 'SFPixelate-Bold';
        color: #01c67f;
        line-height: 17px;
        cursor: pointer;
      }
    }
  }
}
.rent {
  :deep(.ant-modal-content) {
    height: auto;
    background: url('~@/assets/images/market-detail/rent_bg.svg') no-repeat;
    box-shadow: none;
    border-radius: 0;
    padding: 46px 40px 66px;
    border-bottom: 1px solid #048a61;
    .ant-modal-close-x {
      display: none;
    }
    .ant-modal-body {
      padding: 0px;
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h4 {
          font-size: 18px;
          font-family: 'SFPixelate-Bold';
          color: #ffffff;
          line-height: 20px;
        }
        img {
          cursor: pointer;
          width: 18px;
          height: 18px;
        }
      }
      .information {
        display: flex;
        flex-wrap: wrap;
        padding: 43px 0 34px;
        border-bottom: 1px solid #046e4e;
        .commodity {
          width: 80px;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: url('~@/assets/images/market-detail/commodity_bg2.svg') no-repeat center;
          img {
            width: 72px;
            height: 72px;
          }
        }
        .information_main {
          width: calc(100% - 107px);
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-left: 27px;
          .main_left {
            font-size: 14px;
            font-family: 'SFPixelate-Bold';
            font-weight: bold;
            color: #537171;
            line-height: 16px;
            width: 132px;
            p,
            span {
              max-width: 132px;
              text-emphasis: none;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            span {
              color: #ffffff;
              margin: 14px 0;
              display: inline-block;
            }
          }
          .main_right {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 266px;
            .price {
              font-family: 'SFPixelate-Bold';
              img {
                display: inline-block;
                width: 18px;
                height: 18px;
              }
              p {
                display: inline-block;
                font-size: 24px;
                color: #ffffff;
                line-height: 26px;
                margin: 0 0 0 8px;
              }
              i {
                display: block;
                font-size: 12px;
                color: #c0d5d1;
                line-height: 14px;
                margin-top: 3px;
              }
            }
            .time,
            .days {
              font-size: 16px;
              font-family: 'SFPixelate-Bold';
              color: #ffffff;
              line-height: 26px;
              margin: 0 10px;
            }
            .days {
              margin: 0;
            }
          }
        }
        .fee {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: 'SFPixelate-Bold';
          color: #537171;
          line-height: 16px;
          i {
            width: 16px;
            height: 16px;
            margin-left: 8px;
            background: url('~@/assets/images/market-detail/fee_ico.svg') no-repeat center;
          }
          span {
            color: #ffffff;
            margin-left: 24px;
          }
        }
      }
      .rent_main {
        border-bottom: 1px solid #046e4e;
        h5 {
          margin: 16px 0;
          font-size: 14px;
          font-family: 'SFPixelate-Bold';
          color: #537171;
          line-height: 16px;
        }
        .days {
          display: flex;
          font-family: 'SFPixelate-Bold';
          align-items: center;
          padding-bottom: 10px;
          input {
            background: url('~@/assets/images/market-detail/rent_ipt.svg') no-repeat;
            border: none;
            border-radius: 0;
            width: 476px;
            height: 40px;
            padding: 12px 24px;
            font-size: 14px;
            color: #ffffff;
            line-height: 16px;
            &::placeholder {
              color: #527171;
            }
          }
          p {
            font-size: 16px;
            color: #ffffff;
            line-height: 17px;
            margin-left: 8px;
          }
        }
        .price {
          display: flex;
          align-items: center;
          font-family: 'SFPixelate-Bold';
          margin-bottom: 28px;
          span {
            width: 476px;
            height: 40px;
            background: url('~@/assets/images/market-detail/rent_price.svg') no-repeat;
            font-size: 14px;
            color: #ffffff;
            line-height: 16px;
            padding: 12px 24px;
          }
          p {
            font-size: 16px;
            color: #ffffff;
            line-height: 17px;
            margin-left: 8px;
          }
        }
      }
      .ant-collapse {
        background-color: transparent;
        border: none;
        border-radius: 0;
        .ant-collapse-item {
          border: none;
        }
        .ant-collapse-header {
          padding: 16px 0;
          font-size: 16px;
          font-family: 'SFPixelate-Bold';
          color: #ffffff;
          opacity: 0.6;
          line-height: 17px;
          img {
            transition: all 0.3s ease 0s;
            margin-top: -4px;
            right: 0;
          }
        }
        .ant-collapse-content {
          border: none;
          background-color: transparent;
          border-radius: 0;
          .ant-collapse-content-box {
            padding: 0;
          }
          .wallet_input {
            width: 100%;
            height: 40px;
            border: none;
            padding: 12px 24px;
            background: url('~@/assets/images/market-detail/wallet_input.svg') no-repeat;
            color: #ffffff;
            font-size: 16px;
            font-family: 'SFPixelate-Bold';
            &::placeholder {
              font-size: 16px;
              color: #527171;
              line-height: 16px;
            }
          }
          .tips {
            font-size: 12px;
            font-family: 'SFPixelate-Bold';
            color: #537171;
            line-height: 18px;
            margin: 12px 0 30px 0;
            display: block;
          }
        }
      }
      .rent_btn {
        width: 100%;
        height: 41px;
        border: none;
        background: url('~@/assets/images/market-detail/btn_buy.svg') no-repeat center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-family: 'SFPixelate-Bold';
        color: #000000;
        cursor: pointer;
      }
    }
  }
}
.Rent_make {
  :deep(.ant-modal-content) {
    height: auto;
    background: url('~@/assets/images/market-detail/rent_make.svg') no-repeat;
    box-shadow: none;
    border-radius: 0;
    padding: 46px 40px 66px;
    border-bottom: 1px solid #048a61;
    .ant-modal-close-x {
      display: none;
    }
    .ant-modal-body {
      padding: 0;
      .day_main {
        width: 100%;
        height: 40px;
        .ant-select {
          width: 100%;
          .ant-select-selection {
            height: 40px;
            background-color: transparent;
            border: none;
            padding: 12px 32px 12px 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            background: url('~@/assets/images/market-detail/day_bg.svg') no-repeat;
            .ant-select-selection__rendered {
              margin: 0;
              padding: 0;
              position: static;
              margin: 0;
              padding: 0;
              &::after {
                display: none;
              }
              .ant-select-selection-selected-value {
                float: none;
                font-size: 14px;
                font-family: 'SFPixelate-Bold';
                color: #ffffff;
                line-height: 16px;
              }
            }
          }
          .ant-select-arrow {
            position: static;
            width: 14px;
            height: 14px;
            margin: 0;
          }
        }
        .ant-select-focused,
        .ant-select-open {
          .ant-select-selection {
            box-shadow: none;
          }
        }
        p {
          padding-left: 24px;
          font-family: 'SFPixelate-Bold';
          color: #ffffff;
        }
      }
      .title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        h4 {
          font-size: 18px;
          font-family: 'SFPixelate-Bold';
          color: #ffffff;
          line-height: 20px;
        }
        img {
          cursor: pointer;
          width: 18px;
          height: 18px;
        }
      }
      .information {
        display: flex;
        flex-wrap: wrap;
        padding: 43px 0 34px;
        border-bottom: 1px solid #046e4e;
        .commodity {
          width: 80px;
          height: 80px;
          display: flex;
          align-items: center;
          justify-content: center;
          background: url('~@/assets/images/market-detail/commodity_bg2.svg') no-repeat center;
          img {
            width: 72px;
            height: 72px;
          }
        }
        .information_main {
          width: calc(100% - 107px);
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          margin-left: 27px;
          .main_left {
            font-size: 14px;
            font-family: 'SFPixelate-Bold';
            font-weight: bold;
            color: #537171;
            line-height: 16px;
            width: 132px;
            p,
            span {
              max-width: 132px;
              text-emphasis: none;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            span {
              color: #ffffff;
              margin: 14px 0;
              display: inline-block;
            }
          }
          .main_right {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;
            width: 266px;
            .price {
              font-family: 'SFPixelate-Bold';
              img {
                display: inline-block;
                width: 18px;
                height: 18px;
              }
              p {
                display: inline-block;
                font-size: 24px;
                color: #ffffff;
                line-height: 26px;
                margin: 0 0 0 8px;
              }
              i {
                display: block;
                font-size: 12px;
                color: #c0d5d1;
                line-height: 14px;
                margin-top: 3px;
              }
            }
            .time,
            .days {
              font-size: 16px;
              font-family: 'SFPixelate-Bold';
              color: #ffffff;
              line-height: 26px;
              margin: 0 10px;
            }
            .days {
              margin: 0;
            }
          }
        }
        .fee {
          display: flex;
          align-items: center;
          font-size: 14px;
          font-family: 'SFPixelate-Bold';
          color: #537171;
          line-height: 16px;
          i {
            width: 16px;
            height: 16px;
            margin-left: 8px;
            background: url('~@/assets/images/market-detail/fee_ico.svg') no-repeat center;
          }
          span {
            color: #ffffff;
            margin-left: 24px;
          }
        }
      }
      .make_main {
        border-bottom: 1px solid #046e4e;
        padding-bottom: 28px;
        h5 {
          display: flex;
          justify-content: space-between;
          margin: 16px 0;
          margin-top: 26px;
          font-size: 14px;
          font-family: 'SFPixelate-Bold';
          color: #537171;
          line-height: 16px;
          &:first-child {
            margin-top: 16px;
          }
          p {
            color: #ffffff;
          }
        }
        .rental_price {
          display: flex;
          align-items: center;
          font-family: 'SFPixelate-Bold';
          .ant-input-number {
            width: 427px;
            height: 40px;
            background: url('~@/assets/images/market-detail/Rental_Price.svg') no-repeat;
            border: none;
            box-shadow: none;
            .ant-input-number-handler-wrap {
              display: none;
            }
            input {
              padding-left: 24px;
              height: 40px;
              border-radius: 0;
              font-size: 14px;
              color: #ffffff;
              &::placeholder {
                color: #527171;
              }
            }
          }
          p {
            font-size: 16px;
            color: #ffffff;
            line-height: 17px;
            margin-left: 12px;
          }
        }
        .total_period {
          display: flex;
          font-family: 'SFPixelate-Bold';
          align-items: center;
          input {
            background: url('~@/assets/images/market-detail/rent_ipt.svg') no-repeat;
            border: none;
            border-radius: 0;
            width: 476px;
            height: 40px;
            padding: 12px 24px;
            font-size: 14px;
            color: #ffffff;
            line-height: 16px;
            &::placeholder {
              color: #527171;
            }
          }
          p {
            font-size: 16px;
            color: #ffffff;
            line-height: 17px;
            margin-left: 8px;
          }
        }
      }
      .ant-collapse {
        background-color: transparent;
        border: none;
        border-radius: 0;
        .ant-collapse-item {
          border: none;
        }
        .ant-collapse-header {
          padding: 16px 0;
          font-size: 16px;
          font-family: 'SFPixelate-Bold';
          color: #ffffff;
          opacity: 0.6;
          line-height: 17px;
          img {
            transition: all 0.3s ease 0s;
            margin-top: -4px;
            right: 0;
          }
        }
        .ant-collapse-content {
          border: none;
          background-color: transparent;
          border-radius: 0;
          .ant-collapse-content-box {
            padding: 0;
          }
          .wallet_input {
            width: 100%;
            height: 40px;
            border: none;
            padding: 12px 24px;
            background: url('~@/assets/images/market-detail/wallet_input.svg') no-repeat;
            color: #ffffff;
            font-size: 16px;
            font-family: 'SFPixelate-Bold';
            &::placeholder {
              font-size: 16px;
              color: #527171;
              line-height: 16px;
            }
          }
          .tips {
            font-size: 12px;
            font-family: 'SFPixelate-Bold';
            color: #537171;
            line-height: 18px;
            margin: 12px 0 30px 0;
            display: block;
          }
        }
      }
      .offer_day {
        border-top: 1px solid #046e4e;
        margin-bottom: 34px;
        h5 {
          display: flex;
          justify-content: space-between;
          margin: 16px 0;
          margin-top: 28px;
          font-size: 14px;
          font-family: 'SFPixelate-Bold';
          color: #537171;
          line-height: 16px;
        }
      }
      .rent_btn {
        width: 100%;
        height: 41px;
        border: none;
        background: url('~@/assets/images/market-detail/btn_buy.svg') no-repeat center;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-family: 'SFPixelate-Bold';
        color: #000000;
        cursor: pointer;
      }
      .price_box {
        display: flex;
        align-items: center;
        font-family: 'SFPixelate-Bold';
        height: 40px;
        img {
          width: 18px;
          height: 18px;
        }
        h4 {
          font-size: 24px;
          line-height: 26px;
          color: #ffffff;
          margin: 0 12px 0 8px;
        }
        span {
          margin-top: 5px;
          font-size: 12px;
          line-height: 14px;
          color: #ffffff;
        }
      }
      .user_time {
        .time_container {
          background: #09282c;
          color: rgba(255, 255, 255, 0.6);
          height: 40px;
          line-height: 40px;
          padding-left: 24px;
          font-family: 'SFPixelate-Bold';
        }
      }
      .offer_btn {
        display: flex;
        justify-content: center;
        align-items: center;
        button {
          width: 164px;
          height: 42px;
          margin-top: 32px;
          border: none;
          background: url('~@/assets/images/market-detail/make_btn.svg') no-repeat center;
          font-size: 16px;
          font-family: 'SFPixelate-Bold';
          color: #01c67f;
          line-height: 17px;
          cursor: pointer;
        }
      }
    }
  }
}
@media screen and (max-width: 750px) {
  .allTips {
    width: 6.7rem;
    height: 1.3rem;
    margin: 0.4rem auto 0;
    background: url('~@/assets/images/market-detail/alltipsBg_mo.svg') no-repeat;
    background-size: contain;
    padding: 24px;
    p {
      font-size: 0.32rem;
      line-height: 0.44rem;
    }
    button {
      width: 2.26rem;
      height: 0.82rem;
      font-size: 0.32rem;
      background: url('~@/assets/images/market-detail/cancel_mo.svg') no-repeat;
      background-size: contain;
    }
  }
  .detail-box,
  .activity_box {
    width: 100%;
    overflow: hidden;
    padding: 0.6rem 0.4rem 0;
    display: block;
    margin: 0;
    .detail-left {
      width: 100%;
      .nft_name {
        font-size: 0.6rem;
        color: #ffffff;
        line-height: 0.64rem;
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .more {
          width: 0.4rem;
          height: 0.4rem;
          cursor: pointer;
          background: url('~@/assets/images/market-detail/more_ico.svg') no-repeat;
          background-size: contain;
        }
        .domain_box {
          position: absolute;
          height: 0.8rem;
          right: 0;
          bottom: -0.84rem;
          display: flex;
          transition: all 0.2s;
          a {
            width: 0.8rem;
            height: 0.8rem;
            margin-right: 0.08rem;
            display: flex;
            justify-content: center;
            align-items: center;
            background: url('~@/assets/images/market-detail/web_bg.svg') no-repeat;
            background-size: contain;
            img {
              width: 0.48rem;
              height: 0.48rem;
            }
            &:first-child {
              background: url('~@/assets/images/market-detail/ReloadBg_mo.svg') no-repeat center;
              background-size: contain;
            }
            &:last-child {
              background: url('~@/assets/images/market-detail/shareBg_mo.svg') no-repeat center;
              background-size: contain;
              margin-right: 0;
            }
          }
        }
      }
      .series_box {
        margin-top: 0.44rem;
        display: block;
        p {
          font-size: 0.24rem;
          line-height: 0.28rem;
          margin-bottom: 0.3rem;
        }
        span {
          margin: 0 0.32rem 0 0;
          font-size: 0.24rem;
          line-height: 0.28rem;
          padding: 0.02rem 0.08rem;
        }
      }
      .address_box {
        margin-top: 0.42rem;
        font-size: 0.24rem;
        line-height: 0.28rem;
        span {
          margin: 0 0.72rem 0 0.32rem;
        }
        p:last-of-type::before {
          width: 0.28rem;
          height: 0.19rem;
          margin-right: 0.08rem;
          background-size: contain;
        }
      }
      .commodity_box {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 6.7rem;
        background: url('~@/assets/images/market-detail/commodity_mo.svg') no-repeat;
        background-size: contain;
        margin-top: 0.6rem;
        img {
          width: 6rem;
          height: 6rem;
        }
      }
      .buy_box {
        margin-top: 0.72rem;
        height: 7.12rem;
        background: url('~@/assets/images/market-detail/buyBg_mo.svg') no-repeat;
        background-size: contain;
        :deep(.ant-tabs) {
          .ant-tabs-bar {
            padding: 0 0.24rem;
            .ant-tabs-tab {
              font-size: 0.28rem;
              line-height: 0.32rem;
              padding: 0.28rem 0;
              margin: 0 0.3rem 0 0;
            }
            .ant-tabs-nav-container {
              height: auto;
            }
            .ant-tabs-tab-prev,
            .ant-tabs-tab-next {
              display: none;
            }
            .ant-tabs-nav-container-scrolling {
              padding: 0;
            }
            .ant-tabs-nav-scroll {
              overflow-x: scroll;
              &::-webkit-scrollbar {
                display: none;
              }
            }
          }
          .ant-tabs-content {
            margin-top: 0.56rem;
            padding-left: 0.24rem;
            .buy_main {
              p {
                font-size: 0.24rem;
                line-height: 0.28rem;
              }
              .price_box {
                display: block;
                margin-top: 0.24rem;
                img {
                  width: 0.48rem;
                  height: 0.48rem;
                  vertical-align: top;
                  margin-top: 0.08rem;
                }
                h4 {
                  display: inline-block;
                  font-size: 0.6rem;
                  line-height: 0.64rem;
                  margin: 0 0 0 0.16rem;
                }
                span {
                  display: block;
                  margin: 0 0 0 0.68rem;
                  font-size: 0.24rem;
                  line-height: 0.28rem;
                }
              }
              .time_box {
                margin-top: 0.24rem;
                h4 {
                  font-size: 0.24rem;
                  line-height: 0.28rem;
                }
                p {
                  font-size: 0.28rem;
                  line-height: 0.32rem;
                  margin-left: 0.32rem;
                }
              }
              .button_box {
                display: block;
                margin-top: 0.8rem;
                .buy_button,
                .make_button {
                  width: 100%;
                  height: 0.82rem;
                  background: url('~@/assets/images/market-detail/buyBtn_mo.svg') no-repeat;
                  background-size: contain;
                  font-size: 0.32rem;
                  line-height: 0.34rem;
                }
                .make_button {
                  background: url('~@/assets/images/market-detail/makeBtn_mo.svg') no-repeat;
                  background-size: contain;
                  margin: 0.48rem 0 0 0;
                }
              }
              .user_trade {
                .user_trade_ul {
                  display: block;
                  li {
                    &:last-child {
                      margin-top: 0.2rem;
                    }
                  }
                }
                .ant-select {
                  padding: 0.2rem 0.24rem;
                  .ant-select-selection {
                    .ant-select-selection__rendered {
                      font-size: 0.32rem;
                      line-height: 0.34rem;
                    }
                  }
                  .ant-select-arrow {
                    margin-top: -0.14rem;
                    img {
                      width: 0.28rem;
                      height: 0.28rem;
                    }
                  }
                }
                h5 {
                  font-size: 0.24rem;
                  line-height: 0.28rem;
                  margin-bottom: 0.24rem;
                }
                .user_price {
                  flex-wrap: wrap;
                  .price_ipt {
                    .ant-input-number {
                      width: 3.8rem;
                      height: 0.74rem;
                      background: url('~@/assets/images/market-detail/priceIpt_mo.svg') no-repeat;
                      background-size: contain;
                      border: none;
                      box-shadow: none;
                      .ant-input-number-handler-wrap {
                        display: none;
                      }
                      input {
                        height: 0.74rem;
                        padding: 0 0.24rem;
                        font-size: 0.32rem;
                      }
                    }
                  }
                  .price_type {
                    width: 2.18rem;
                    height: 0.74rem;
                    margin: 0 0 0 0.24rem;
                    background: url('~@/assets/images/market-detail/priceType_mo.svg') no-repeat;
                    background-size: contain;
                  }
                  span {
                    font-size: 0.24rem;
                    line-height: 0.28rem;
                    margin-top: 0.16rem;
                  }
                }
                .price_box {
                  img {
                    width: 0.48rem;
                    height: 0.48rem;
                  }
                  h4 {
                    font-size: 0.6rem;
                    line-height: 0.64rem;
                  }
                  span {
                    margin-left: 0.68rem;
                    font-size: 0.24rem;
                    line-height: 0.28rem;
                  }
                }
                .user_time {
                  margin-top: 0.3rem;
                  p {
                    font-size: 0.24rem;
                    line-height: 0.28rem;
                    margin-bottom: 0.1rem;
                    span {
                      font-size: 0.28rem;
                      line-height: 0.32rem;
                    }
                  }
                  .time_container {
                    width: 6.2rem;
                    height: 0.74rem;
                    background: url('~@/assets/images/market-detail/userTime_mo.svg') no-repeat;
                    background-size: contain;
                  }
                  .time_containerNo {
                    background: url('~@/assets/images/market-detail/userTime_mo2.svg') no-repeat;
                    background-size: contain;
                    span {
                      height: 0.74rem;
                      line-height: 0.74rem;
                      padding-left: 0.24rem;
                      font-size: 0.28rem;
                    }
                    .ant-select {
                      img {
                        display: none;
                      }
                    }
                  }
                }
                .lssue {
                  margin-top: 0.36rem;
                  button {
                    font-size: 0.32rem;
                    width: 6.22rem;
                    height: 0.82rem;
                    background: url('~@/assets/images/market-detail/buyBtn_mo.svg') no-repeat;
                    background-size: contain;
                  }
                  button[disabled] {
                    background: url('~@/assets/images/market-detail/issueBtn_mo.svg') no-repeat;
                    background-size: contain;
                  }
                }
                .cancel_offer_btn {
                  margin-top: 0.38rem;
                  button {
                    width: 100%;
                    height: 0.82rem;
                    background: url('~@/assets/images/market-detail/makeBtn_mo.svg') no-repeat center;
                    background-size: contain;
                    font-size: 0.32rem;
                    line-height: 0.34rem;
                  }
                }
              }
            }
            .Rental_main {
              ul {
                display: inline-block;
                justify-content: space-between;
                li {
                  margin-right: 0.64rem;
                  p {
                    font-size: 0.24rem;
                    line-height: 0.28rem;
                    margin-bottom: 0.1rem;
                  }
                  span {
                    font-size: 0.28rem;
                    line-height: 0.32rem;
                    margin-top: 0.08rem;
                  }
                  .flex {
                    display: block;
                    img {
                      width: 0.48rem;
                      height: 0.48rem;
                      vertical-align: top;
                      margin-top: 0.08rem;
                    }
                    span {
                      font-size: 0.6rem;
                      line-height: 0.64rem;
                    }
                    i {
                      display: block;
                      margin: 0 0 0 0.68rem;
                      font-size: 0.24rem;
                      line-height: 0.28rem;
                    }
                  }
                }
              }
              .time_box {
                margin-top: 0.28rem;
                ul {
                  display: flex;
                  li {
                    margin-right: 0.4rem;
                    &:last-child {
                      margin: 0;
                    }
                    p {
                      margin: 0;
                      font-size: 0.24rem;
                      line-height: 0.28rem;
                      color: #537171;
                    }
                    span {
                      font-size: 0.28rem;
                      line-height: 0.32rem;
                      color: #ffffff;
                      margin-top: 0.24rem;
                    }
                  }
                }
              }
              .button_box {
                display: block;
                margin-top: 0.38rem;
                .buy_button,
                .make_button {
                  width: 100%;
                  height: 0.82rem;
                  background: url('~@/assets/images/market-detail/buyBtn_mo.svg') no-repeat;
                  background-size: contain;
                  font-size: 0.32rem;
                  line-height: 0.34rem;
                }
                .make_button {
                  background: url('~@/assets/images/market-detail/makeBtn_mo.svg') no-repeat;
                  background-size: contain;
                  margin: 0.38rem 0 0 0;
                }
              }
              .user_rental {
                .ant-select {
                  padding: 0.2rem 0.24rem;
                  .ant-select-selection {
                    .ant-select-selection__rendered {
                      font-size: 0.32rem;
                      line-height: 0.34rem;
                    }
                  }
                  .ant-select-arrow {
                    margin-top: -0.14rem;
                    img {
                      width: 0.28rem;
                      height: 0.28rem;
                    }
                  }
                }
                .user_price {
                  margin-top: -0.24rem;
                  h5 {
                    font-size: 0.24rem;
                    line-height: 0.28rem;
                    margin-bottom: 0.24rem;
                  }
                  .price_ipt {
                    .ant-input-number {
                      width: 3.8rem;
                      height: 0.74rem;
                      background: url('~@/assets/images/market-detail/priceIpt_mo.svg') no-repeat;
                      background-size: contain;
                      border: none;
                      box-shadow: none;
                      .ant-input-number-handler-wrap {
                        display: none;
                      }
                      input {
                        height: 0.74rem;
                        padding: 0 0.24rem;
                        font-size: 0.32rem;
                      }
                    }
                  }
                  .price_type {
                    width: 2.18rem;
                    height: 0.74rem;
                    margin: 0 0 0 0.24rem;
                    background: url('~@/assets/images/market-detail/priceType_mo.svg') no-repeat;
                    background-size: contain;
                  }
                  & > span {
                    width: 90%;
                    margin: 0.16rem 0 0.2rem;
                    font-size: 0.24rem;
                    line-height: 0.28rem;
                  }
                  .rental_time {
                    margin: 0;
                    margin-right: 0.48rem;
                    .rentalTime_box {
                      width: 2.04rem;
                      height: 0.74rem;
                      background: url('~@/assets/images/market-detail/rentalTime_mo.svg') no-repeat;
                      background-size: contain;
                    }
                  }
                  .period_box {
                    input {
                      width: 3.68rem;
                      height: 0.74rem;
                      padding: 0.2rem 0.24rem;
                      font-size: 0.28rem;
                      background: url('~@/assets/images/market-detail/priceIpt2_mo.svg') no-repeat;
                      background-size: contain;
                    }
                  }
                }
                .user_time {
                  margin-top: 0.24rem;
                  p {
                    font-size: 0.24rem;
                    line-height: 0.28rem;
                    margin-bottom: 0.1rem;
                    span {
                      font-size: 0.28rem;
                      line-height: 0.32rem;
                    }
                  }
                  .time_container {
                    width: 6.2rem;
                    height: 0.74rem;
                    background: url('~@/assets/images/market-detail/userTime_mo.svg') no-repeat;
                    background-size: contain;
                  }
                  .time_containerNo {
                    background: url('~@/assets/images/market-detail/userTime_mo2.svg') no-repeat;
                    background-size: contain;
                    span {
                      height: 0.74rem;
                      line-height: 0.74rem;
                      padding-left: 0.24rem;
                      font-size: 0.28rem;
                    }
                    .ant-select {
                      img {
                        display: none;
                      }
                    }
                  }
                }
                .lssue {
                  margin-top: 0.2rem;
                  button {
                    font-size: 0.32rem;
                    width: 6.22rem;
                    height: 0.82rem;
                    background: url('~@/assets/images/market-detail/buyBtn_mo.svg') no-repeat;
                    background-size: contain;
                  }
                  button[disabled] {
                    background: url('~@/assets/images/market-detail/issueBtn_mo.svg') no-repeat;
                    background-size: contain;
                  }
                }
                .cancel_offer_btn {
                  margin-top: 0.2rem;
                  button {
                    width: 100%;
                    height: 0.82rem;
                    background: url('~@/assets/images/market-detail/makeBtn_mo.svg') no-repeat center;
                    background-size: contain;
                    font-size: 0.32rem;
                    line-height: 0.34rem;
                  }
                }
                ul {
                  display: flex;
                  flex-wrap: wrap;
                  padding-right: 0.24rem;
                  li:first-child {
                    width: 80%;
                    margin-bottom: 0.2rem;
                  }
                }
              }
            }
          }
        }
      }
      .office_box {
        height: 8.54rem;
        margin: 0.32rem 0;
        overflow: hidden;
        background: url('~@/assets/images/market-detail/officeBg_mo.png') no-repeat;
        background-size: cover;
        :deep(.ant-collapse) {
          border: none;
          .ant-collapse-item {
            .ant-collapse-header {
              padding: 0.28rem 0.24rem;
              font-size: 0.28rem;
              line-height: 0.32rem;
              img {
                right: 0.24rem;
                width: 0.28rem;
                height: 0.28rem;
                margin-top: -0.14rem;
              }
            }
            .ant-collapse-content {
              height: 7.7rem;
              .ant-collapse-content-box {
                padding: 0.02rem 0.06rem;
                overflow-x: scroll;
                &::-webkit-scrollbar {
                  width: 36px;
                  height: 0.04rem;
                }
                &::-webkit-scrollbar-thumb {
                  background-color: #039564;
                  border-radius: 12px;
                }
                &::-webkit-scrollbar-track {
                  display: none;
                }
                table {
                  padding-left: 0.24rem;
                  .title {
                    padding: 0.16rem 0 0.16rem 0.24rem;
                  }
                  .table_main {
                    height: 6.62rem;
                  }
                  .no_offers {
                    height: 6.62rem;
                    width: 100%;
                    p {
                      width: 4rem;
                      height: 2.54rem;
                      background-size: contain;
                      font-size: 0.24rem;
                      line-height: 0.28rem;
                      padding: 1rem 0 0 0.8rem;
                    }
                  }
                  tr {
                    padding: 0.32rem 0.24rem 0.3rem;
                    td {
                      font-size: 0.24rem;
                      line-height: 0.28rem;
                      &:nth-child(1) {
                        width: 2.24rem;
                        margin-right: 0.16rem;
                        p {
                          margin: 0 0.08rem 0 0.04rem;
                        }
                      }
                      &:nth-child(2) {
                        width: 2.24rem;
                        margin-right: 0.22rem;
                      }
                      &:nth-child(3) {
                        width: 1.5rem;
                        margin-right: 0.69rem;
                      }
                      &:nth-child(4) {
                        width: 2.5rem;
                        p {
                          color: #01c67f;
                        }
                      }
                      img {
                        width: 0.32rem;
                        height: 0.32rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .officeHeight {
        height: 0.88rem !important;
      }
    }
    .detail-right {
      width: 100%;
      .information_box {
        width: 100%;
        height: 5.8rem;
        margin: 0.32rem 0;
        background: url('~@/assets/images/market-detail/informationBg_mo.svg') no-repeat;
        background-size: cover;
        :deep(.ant-collapse) {
          border: none;
          .ant-collapse-item {
            .ant-collapse-header {
              padding: 0.28rem 0.24rem;
              font-size: 0.28rem;
              line-height: 0.32rem;
              img {
                right: 0.24rem;
                width: 0.28rem;
                height: 0.28rem;
                margin-top: -0.14rem;
              }
            }
            .ant-collapse-content {
              .ant-collapse-content-box {
                padding: 0.5rem 0.24rem 0;
                ul {
                  li {
                    margin-bottom: 0.32rem;
                    font-size: 0.24rem;
                    line-height: 0.28rem;
                  }
                  .fee {
                    p {
                      i {
                        width: 0.32rem;
                        height: 0.32rem;
                        background-size: contain;
                        margin-left: 0.16rem;
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      .informationHeight {
        height: 0.88rem !important;
      }
    }
  }
  .activity_box {
    width: calc(100% - 0.8rem);
    margin: 0 0.4rem;
    margin-bottom: 2.44rem;
    height: 10.34rem;
    background: url('~@/assets/images/market-detail/activityBg_mo.svg') no-repeat;
    background-size: cover;
    padding: 0;
    :deep(.ant-collapse) {
      border: none;
      .ant-collapse-item {
        .ant-collapse-header {
          padding: 0.28rem 0.24rem;
          font-size: 0.28rem;
          line-height: 0.32rem;
          img {
            width: 0.28rem;
            height: 0.28rem;
            right: 0.24rem;
          }
        }
      }
    }
    .lable_box {
      overflow-x: scroll;
      &::-webkit-scrollbar {
        display: none;
      }
      :deep(.ant-radio-group-default) {
        padding: 0.24rem 0.24rem;
        width: 18.2rem;
        .ant-radio-button-wrapper {
          margin-right: 0.16rem;
          span:last-of-type {
            font-size: 0.24rem;
            line-height: 0.28rem;
            padding: 0.12rem 0.28rem;
          }
        }
      }
    }
    .activity_main {
      height: 8.14rem;
      // overflow: scroll;

      &::-webkit-scrollbar {
        width: 0.06rem;
        height: 0.06rem;
      }
      &::-webkit-scrollbar-corner {
        display: none;
      }
      .activity-info {
        width: 13.9rem;
        padding: 0.32rem 0 0.28rem;
        .user_img {
          flex-shrink: 0;
          width: 0.84rem;
          height: 0.84rem;
          margin: 0 0.16rem 0 0.24rem;
        }
        .user_information {
          display: flex;
          justify-content: space-between;
          flex-shrink: 0;
          .price_box {
            font-size: 0.24rem;
            line-height: 0.24rem;
            margin-left: 0.44rem;
            img {
              width: 0.32rem;
              height: 0.32rem;
            }
            p {
              margin: 0 0.14rem;
            }
          }
          .rent-box {
            display: flex;
            p {
              color: #ffffff;
              margin-left: 0.16rem;
              font-size: 0.24rem;
            }
          }
        }
        .user_name {
          font-size: 0.24rem;
          line-height: 0.28rem;
          margin-bottom: 0.28rem;
          span {
            margin-left: 0.2rem;
            padding: 0 0.12rem;
          }
        }
        .right_box {
          width: auto;
          .user_from {
            font-size: 0.28rem;
            line-height: 0.28rem;
            p {
              margin: 0 0.28rem;
            }
            span {
              :deep(.ant-btn) {
                font-size: 0.24rem;
                line-height: 0.24rem;
              }
            }
            a {
              width: 0.32rem;
              height: 0.32rem;
              background-size: contain;
              margin-left: 0.2rem;
            }
          }
        }
      }
    }
  }
  .activityHeight {
    height: 0.88rem;
  }
  .buyNow,
  .make_box {
    :deep(.ant-modal) {
      width: 7.1rem !important;
      .ant-modal-content {
        padding: 0.92rem 0.24rem 1.32rem;
        height: 7.82rem;
        background: url('~@/assets/images/market/authorization_mo.svg') no-repeat;
        background-size: contain;
        .ant-modal-body {
          .title {
            h4 {
              font-size: 0.36rem;
              line-height: 0.4rem;
            }
            img {
              width: 0.36rem;
              height: 0.36rem;
            }
          }
          .information {
            padding: 0.86rem 0 0.56rem;
            border-bottom: 0.02rem solid #046e4e;
            .commodity {
              width: 1.6rem;
              height: 1.6rem;
              background: url('~@/assets/images/market-detail/commodityBg2_mo.svg') no-repeat;
              background-size: contain;
              img {
                width: 1.42rem;
                height: 1.42rem;
              }
            }
            .information_main {
              width: calc(100% - 2rem);
              margin-left: 0.16rem;
              .main_left {
                font-size: 0.28rem;
                line-height: 0.32rem;
                span {
                  margin: 0.28rem 0 0.32rem;
                }
                .fee {
                  i {
                    width: 0.32rem;
                    height: 0.32rem;
                    background-size: contain;
                  }
                }
              }
              .main_right {
                .price {
                  display: block;
                  text-align: right;
                  img {
                    display: inline-block;
                    width: 0.36rem;
                    height: 0.36rem;
                    margin-top: -0.18rem;
                  }
                  p {
                    display: inline-block;
                    font-size: 0.48rem;
                    line-height: 0.52rem;
                    margin: 0;
                    margin-left: 0.24rem;
                  }
                  i {
                    font-size: 0.24rem;
                    line-height: 0.28rem;
                  }
                }
                span {
                  font-size: 0.28rem;
                  line-height: 0.32rem;
                  margin-top: 0.38rem;
                }
              }
            }
          }
          .ant-collapse {
            .ant-collapse-header {
              padding: 0.32rem 0;
              font-size: 0.32rem;
              line-height: 0.34rem;
              img {
                width: 0.28rem;
                margin-top: -0.08rem;
              }
            }
            .ant-collapse-content {
              .wallet_input {
                height: 0.8rem;
                padding: 0.24rem;
                font-size: 0.28rem;
                background: url('~@/assets/images/market-detail/walletInput_mo.svg') no-repeat;
                background-size: contain;
                &::placeholder {
                  font-size: 0.28rem;
                  line-height: 0.32rem;
                }
              }
              .tips {
                font-size: 0.24rem;
                line-height: 0.36rem;
              }
            }
          }
          .btn_buy {
            width: 100%;
            height: 0.82rem;
            background: url('~@/assets/images/market-detail/btMake_mo.svg') no-repeat;
            background-size: contain;
            font-size: 0.32rem;
          }
        }
      }
    }
    &-collapse {
      :deep(.ant-modal-content) {
        height: 9.84rem !important;
        background: url('~@/assets/images/market-detail/buyNowBg_mo.svg') no-repeat !important;
        background-size: contain !important;
      }
    }
  }
  .make_box {
    :deep(.ant-modal-content) {
      height: auto !important;
      background: url('~@/assets/images/market-detail/rentMake_mo.svg') no-repeat center top !important;
      background-size: cover !important;
      .balance_box {
        font-size: 0.28rem;
        line-height: 0.32rem;
        margin: 0.32rem 0;
      }
      .balance_ipt {
        width: 5.78rem;
        height: 0.8rem;
        background: url('~@/assets/images/market-detail/balanceIpt_mo.svg') no-repeat;
        background-size: contain;
        .ant-input-number-handler-wrap {
          display: none;
        }
        input {
          padding-left: 0.24rem;
          height: 0.8rem;
          font-size: 0.28rem;
          font-family: 'SFPixelate-Bold';
          color: #ffffff;
        }
      }
      .eth {
        font-size: 0.24rem;
        line-height: 0.34rem;
        margin-left: 0.16rem;
      }
      .title_day {
        margin: 0.52rem 0 0.32rem;
        font-size: 0.28rem;
        line-height: 0.32rem;
      }
      .day_main {
        height: 0.8rem;
        .ant-select {
          .ant-select-selection {
            height: 0.8rem;
            padding: 0.24rem;
            background: url('~@/assets/images/market-detail/dayBg_mo.svg') no-repeat;
            background-size: contain;
            .ant-select-selection__rendered {
              .ant-select-selection-selected-value {
                font-size: 0.28rem;
                line-height: 0.32rem;
              }
            }
          }
          .ant-select-arrow {
            width: 0.28rem;
          }
        }
      }
      .make_btn {
        height: 0.82rem;
        margin-top: 0.64rem;
        background: url('~@/assets/images/market-detail/btMake_mo.svg') no-repeat;
        background-size: contain;
        font-size: 0.32rem;
      }
      .price_box {
        height: 0.8rem;
        img {
          width: 0.36rem;
          height: 0.36rem;
        }
        h4 {
          font-size: 0.48rem;
          line-height: 0.52rem;
          color: #ffffff;
          margin: 0 0.24rem 0 0.16rem;
        }
        span {
          margin-top: 0.1rem;
          font-size: 0.24rem;
          line-height: 0.28rem;
        }
      }
      .user_time {
        .time_container {
          height: 0.8rem;
          line-height: 0.8rem;
          padding-left: 0.34rem;
        }
      }
      .offer_btn {
        button {
          width: 100%;
          height: 0.82rem;
          margin-top: 0.64rem;
          background: url('~@/assets/images/market-detail/makeBtn_mo.svg') no-repeat center;
          background-size: contain;
          font-size: 0.32rem;
          line-height: 0.34rem;
        }
      }
    }
  }
  .rent {
    :deep(.ant-modal) {
      width: 7.1rem !important;
      .ant-modal-content {
        padding: 0.92rem 0.24rem 1.32rem;
        background: url('~@/assets/images/market-detail/rentBg_mo.svg') no-repeat;
        background-size: contain;
        .ant-modal-body {
          .title {
            h4 {
              font-size: 0.36rem;
              line-height: 0.4rem;
            }
            img {
              width: 0.36rem;
              height: 0.36rem;
            }
          }
          .information {
            padding: 0.86rem 0 0.56rem;
            border-bottom: 0.02rem solid #046e4e;
            .commodity {
              width: 1.6rem;
              height: 1.6rem;
              background: url('~@/assets/images/market-detail/commodityBg2_mo.svg') no-repeat;
              background-size: contain;
              img {
                width: 1.42rem;
                height: 1.42rem;
              }
            }
            .information_main {
              width: calc(100% - 2rem);
              margin-left: 0.16rem;
              .main_left {
                font-size: 0.28rem;
                line-height: 0.32rem;
                width: auto;
                p,
                span {
                  max-width: 100%;
                  overflow: visible;
                }
                span {
                  margin: 0.16rem 0;
                }
              }
              .main_right {
                width: 100%;
                .price {
                  display: block;
                  text-align: right;
                  img {
                    display: inline-block;
                    width: 0.36rem;
                    height: 0.36rem;
                    margin-top: -0.18rem;
                  }
                  p {
                    display: inline-block;
                    font-size: 0.48rem;
                    line-height: 0.52rem;
                    margin: 0;
                    margin-left: 0.24rem;
                  }
                  i {
                    font-size: 0.24rem;
                    line-height: 0.28rem;
                    margin: 0;
                  }
                }
                .time,
                .days {
                  font-size: 0.28rem;
                  line-height: 0.32rem;
                  margin: 0.1rem 0 0;
                }
                span {
                  font-size: 0.28rem;
                  line-height: 0.32rem;
                  margin-top: 0.38rem;
                }
              }
            }
            .fee {
              width: 100%;
              font-size: 0.28rem;
              line-height: 0.32rem;
              justify-content: space-between;
              em {
                i {
                  display: inline-block;
                  width: 0.32rem;
                  height: 0.32rem;
                  background-size: contain;
                  margin-left: 0.16rem;
                }
              }
              span {
                margin: 0;
              }
            }
          }
          .rent_main {
            h5 {
              font-size: 0.28rem;
              line-height: 0.32rem;
              margin: 0.32rem 0;
            }
            .days {
              padding-bottom: 0.2rem;
              input {
                width: 5.78rem;
                height: 0.8rem;
                padding: 0.24rem;
                font-size: 0.28rem;
                line-height: 0.32rem;
                background: url('~@/assets/images/market-detail/rentIpt_mo.svg') no-repeat;
                background-size: contain;
              }
              p {
                margin-left: 0.16rem;
                font-size: 0.32rem;
                line-height: 0.34rem;
              }
            }
            .price {
              margin-bottom: 0.56rem;
              span {
                width: 5.78rem;
                height: 0.8rem;
                background: url('~@/assets/images/market-detail/rentPrice_mo.svg') no-repeat;
                background-size: contain;
                padding: 0.24rem 0.2rem;
                font-size: 0.28rem;
                line-height: 0.32rem;
              }
              p {
                margin-left: 0.16rem;
                font-size: 0.32rem;
                line-height: 0.34rem;
              }
            }
          }
          .ant-collapse {
            .ant-collapse-header {
              padding: 0.56rem 0 0.32rem;
              font-size: 0.32rem;
              line-height: 0.34rem;
              img {
                width: 0.28rem;
                margin-top: -0.08rem;
              }
            }
            .ant-collapse-content {
              .wallet_input {
                height: 0.8rem;
                padding: 0.24rem;
                font-size: 0.28rem;
                background: url('~@/assets/images/market-detail/walletInput_mo.svg') no-repeat;
                background-size: contain;
                &::placeholder {
                  font-size: 0.28rem;
                  line-height: 0.32rem;
                }
              }
              .tips {
                font-size: 0.24rem;
                line-height: 0.36rem;
                margin-bottom: 0.6rem;
              }
            }
          }
          .rent_btn {
            width: 100%;
            height: 0.82rem;
            background: url('~@/assets/images/market-detail/btMake_mo.svg') no-repeat;
            background-size: contain;
            font-size: 0.32rem;
          }
        }
      }
    }
    &-collapse {
      :deep(.ant-modal-content) {
        background-size: cover !important;
      }
    }
  }
  .Rent_make {
    :deep(.ant-modal) {
      width: 7.1rem !important;
      .ant-modal-content {
        padding: 0.92rem 0.24rem 1.32rem;
        background: url('~@/assets/images/market-detail/rentMake_mo.svg') no-repeat;
        background-size: contain;
        .ant-modal-body {
          .day_main {
            height: 0.8rem;
            .ant-select {
              .ant-select-selection {
                height: 0.8rem;
                padding: 0.24rem;
                background: url('~@/assets/images/market-detail/dayBg_mo.svg') no-repeat;
                background-size: contain;
                .ant-select-selection__rendered {
                  .ant-select-selection-selected-value {
                    font-size: 0.28rem;
                    line-height: 0.32rem;
                  }
                }
              }
              .ant-select-arrow {
                width: 0.28rem;
              }
            }
            p {
              padding-left: 0.24rem;
            }
          }
          .title {
            h4 {
              font-size: 0.36rem;
              line-height: 0.4rem;
            }
            img {
              width: 0.36rem;
              height: 0.36rem;
            }
          }
          .information {
            padding: 0.5rem 0 0.36rem;
            border-bottom: 0.02rem solid #046e4e;
            .commodity {
              width: 1.6rem;
              height: 1.6rem;
              background: url('~@/assets/images/market-detail/commodityBg2_mo.svg') no-repeat;
              background-size: contain;
              img {
                width: 1.42rem;
                height: 1.42rem;
              }
            }
            .information_main {
              width: calc(100% - 2rem);
              margin-left: 0.16rem;
              .main_left {
                font-size: 0.28rem;
                line-height: 0.32rem;
                width: auto;
                p,
                span {
                  max-width: 100%;
                  overflow: visible;
                }
                span {
                  margin: 0.16rem 0;
                }
              }
              .main_right {
                width: 100%;
                .price {
                  display: block;
                  text-align: right;
                  img {
                    display: inline-block;
                    width: 0.36rem;
                    height: 0.36rem;
                    margin-top: -0.18rem;
                  }
                  p {
                    display: inline-block;
                    font-size: 0.48rem;
                    line-height: 0.52rem;
                    margin: 0;
                    margin-left: 0.24rem;
                  }
                  i {
                    font-size: 0.24rem;
                    line-height: 0.28rem;
                    margin: 0;
                  }
                }
                .time,
                .days {
                  font-size: 0.28rem;
                  line-height: 0.32rem;
                  margin: 0.1rem 0.1rem 0;
                }
                span {
                  font-size: 0.28rem;
                  line-height: 0.32rem;
                  margin-top: 0.38rem;
                }
              }
            }
            .fee {
              width: 100%;
              font-size: 0.28rem;
              line-height: 0.32rem;
              justify-content: space-between;
              em {
                i {
                  display: inline-block;
                  width: 0.32rem;
                  height: 0.32rem;
                  background-size: contain;
                  margin-left: 0.16rem;
                }
              }
              span {
                margin: 0;
              }
            }
          }
          .make_main {
            padding-bottom: 0.56rem;
            h5 {
              font-size: 0.28rem;
              line-height: 0.32rem;
              margin: 0.52rem 0 0.32rem 0;
              &:first-child {
                margin-top: 0.32rem;
              }
            }
            .rental_price {
              .ant-input-number {
                width: 4.88rem;
                height: 0.8rem;
                background: url('~@/assets/images/market-detail/RentalPrice_mo.svg') no-repeat;
                background-size: contain;
                input {
                  padding-left: 0.24rem;
                  height: 0.8rem;
                  font-size: 0.28rem;
                }
              }
              p {
                font-size: 0.32rem;
                line-height: 0.34rem;
                margin-left: 0.16rem;
              }
            }
            .total_period {
              input {
                width: 5.78rem;
                height: 0.8rem;
                background: url('~@/assets/images/market-detail/rentIpt_mo.svg') no-repeat;
                background-size: contain;
                padding: 0.24rem;
                font-size: 0.28rem;
                line-height: 0.32rem;
              }
              p {
                font-size: 0.32rem;
                line-height: 0.34rem;
                margin-left: 0.16rem;
              }
            }
          }
          .ant-collapse {
            .ant-collapse-header {
              padding: 0.56rem 0 0.32rem;
              font-size: 0.32rem;
              line-height: 0.34rem;
              img {
                width: 0.28rem;
                margin-top: -0.08rem;
              }
            }
            .ant-collapse-content {
              .wallet_input {
                height: 0.8rem;
                padding: 0.24rem;
                font-size: 0.28rem;
                background: url('~@/assets/images/market-detail/walletInput_mo.svg') no-repeat;
                background-size: contain;
                &::placeholder {
                  font-size: 0.28rem;
                  line-height: 0.32rem;
                }
              }
              .tips {
                font-size: 0.24rem;
                line-height: 0.36rem;
                margin: 0;
                margin-bottom: 0.6rem;
              }
            }
          }
          .offer_day {
            border-top: 1px solid #046e4e;
            margin-bottom: 0.6rem;
            h5 {
              display: flex;
              justify-content: space-between;
              margin: 0.56rem 0 0.32rem 0;
              font-size: 0.28rem;
              font-family: 'SFPixelate-Bold';
              color: #537171;
              line-height: 0.32rem;
            }
          }
          .rent_btn {
            width: 100%;
            height: 0.82rem;
            background: url('~@/assets/images/market-detail/btMake_mo.svg') no-repeat;
            background-size: contain;
            font-size: 0.32rem;
          }
          .price_box {
            height: 0.8rem;
            img {
              width: 0.36rem;
              height: 0.36rem;
            }
            h4 {
              font-size: 0.48rem;
              line-height: 0.52rem;
              color: #ffffff;
              margin: 0 0.24rem 0 0.16rem;
            }
            span {
              margin-top: 0.1rem;
              font-size: 0.24rem;
              line-height: 0.28rem;
            }
          }
          .user_time {
            .time_container {
              height: 0.8rem;
              line-height: 0.8rem;
              padding-left: 0.34rem;
            }
          }
          .offer_btn {
            button {
              width: 100%;
              height: 0.82rem;
              margin-top: 0.64rem;
              background: url('~@/assets/images/market-detail/makeBtn_mo.svg') no-repeat center;
              background-size: contain;
              font-size: 0.32rem;
              line-height: 0.34rem;
            }
          }
        }
      }
    }
    &-collapse {
      :deep(.ant-modal-content) {
        background-size: cover !important;
      }
    }
  }
}
.cyrox-modal {
  :deep(.ant-modal-mask) {
    background-color: rgba(0, 0, 0, 0.72);
  }
  :deep(.ant-modal) {
    padding-bottom: 0;
    .ant-modal-content {
      background: transparent;
      box-shadow: none;
      .ant-modal-close {
        top: -12px;
        right: 24px;
        .ant-modal-close-x {
          width: 24px;
          line-height: normal;
          background: url('~@/assets/images/close.svg') no-repeat top center;
          background-size: contain;
          display: block;
          .ant-modal-close-icon {
            display: none;
          }
        }
      }
    }
  }
  .cyrox-modal-info {
    box-shadow: 0px 4px 15px 0px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
    padding: 36px 48px 58px;
    .cyrox-modal-title {
      font-size: 18px;
      font-family: 'Lucida-Grande-Bold';
      text-align: center;
      color: #ff503e;
      span {
        color: #ffffff;
        padding-bottom: 38px;
        display: block;
      }
    }
    .cyrox-modal-body {
      padding-top: 10px;
      font-size: 14px;
      line-height: 20px;
      p {
        padding-top: 20px;
      }
    }
  }
  .cyrox-modal-image {
    img {
      height: 86vh;
    }
  }
}
.cyrox-modal-mobile {
  .cyrox-modal-info {
    padding: 0.48rem 0.7rem 0.68rem;
  }
  .cyrox-modal-image {
    img {
      width: 100%;
      height: auto;
    }
  }
}
</style>
