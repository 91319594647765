import { render, staticRenderFns } from "./market-detail.vue?vue&type=template&id=12ab1b2c&scoped=true&"
import script from "./market-detail.vue?vue&type=script&lang=js&"
export * from "./market-detail.vue?vue&type=script&lang=js&"
import style0 from "./market-detail.vue?vue&type=style&index=0&id=12ab1b2c&prod&lang=scss&"
import style1 from "./market-detail.vue?vue&type=style&index=1&id=12ab1b2c&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "12ab1b2c",
  null
  
)

export default component.exports